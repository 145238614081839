import React, { Component } from 'react';
import Vimeo from '@u-wave/react-vimeo';

class ModuleDoubleAsset extends Component {
  render() {
    let layoutClass = '';

    const { item } = this.props;
    
    const layout = item.fields.layout,
          primaryAsset = item.fields.primaryAsset,
          secondaryAsset = item.fields.secondaryAsset;

    if (layout === 'Primary Asset First 1/3') {
      layoutClass = 'text-single-video-double'; 
    } else if (layout === 'Primary Asset First 1/2') {
      layoutClass = 'split';
    } else if (layout === 'Primary Asset First 2/3') {
      layoutClass = 'text-double-video-single';
    } else if (layout === 'Primary Asset Second 1/3') {
      layoutClass = 'flip  text-single-video-double';
    } else if (layout === 'Primary Asset Second 1/2') {
      layoutClass = 'flip split';
    } else if (layout === 'Primary Asset Second 2/3') {
      layoutClass = 'flip text-double-video-single';
    } else {
      layoutClass = 'text-single-video-double';
    }

    return (
      <div className={`mod__mixed mod-styles doubleAsset ${layoutClass}`}>
          <div className="mod-body">
            {primaryAsset && (
              <div>
                <div className={`mod-media__wrapper ${primaryAsset.fields.hideRedBar ? 'hide-bar' : ''}`}>
                  {primaryAsset.fields.assetType === true && (
                    <Vimeo
                      video={primaryAsset.fields.vimeoID}
                      responsive={true}
                      showTitle={false}
                      showPortrait={false}
                      showByline={false}
                      color='c00000'/>
                  )}
                  {primaryAsset.fields.assetType === false && (
                    <img src={primaryAsset.fields.asset.fields.file.url} alt={primaryAsset.fields.asset.fields.title}/>
                  )}
                </div>

                {primaryAsset.fields.caption && (
                  <div className="mod__caption" dangerouslySetInnerHTML={{ __html: primaryAsset.fields.caption }} />
                )}
              
              </div>
            )}
          </div>
          <div className="mod-media">
            {secondaryAsset && (
              <div>
                <div className={`mod-media__wrapper ${secondaryAsset.fields.hideRedBar ? 'hide-bar' : ''}`}>
                  {secondaryAsset.fields.assetType === true && (
                    <Vimeo
                      video={secondaryAsset.fields.vimeoID}
                      responsive={true}
                      showTitle={false}
                      showPortrait={false}
                      showByline={false}
                      color='c00000'/>
                  )}
                  {secondaryAsset.fields.assetType === false && (
                    <img src={secondaryAsset.fields.asset.fields.file.url} alt={secondaryAsset.fields.asset.fields.title}/>
                  )}
                </div>
                {secondaryAsset.fields.caption && (
                  <div className="mod__caption" dangerouslySetInnerHTML={{ __html: secondaryAsset.fields.caption }} />
                )}
              </div>
            )}
            
            
          </div>
        </div>
    );
  }
}

export default ModuleDoubleAsset;

/* 
{assetType === true && (  
          <div>
            <div className="mod__full--inner">
              <Vimeo
                video={videoID}
                responsive={true}
                showTitle={false}
                showPortrait={false}
                showByline={false}
                color='c00000'/>
            </div>
            <div className="mod__caption" dangerouslySetInnerHTML={{ __html: caption }} />
          </div>
        )}
        {assetType === false && (
          <div>  
            <div className="mod__full--inner">    
              <img src={image} alt={title}/>
            </div>
            <div className="mod__caption" dangerouslySetInnerHTML={{ __html: caption }} />
          </div>
        )}
        */