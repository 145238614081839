import React, { Component } from 'react';
import ReactGA from 'react-ga';

class SearchForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            background: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const value = event.target.value;

        if (value === '') {
            this.setState({
                background: false
            }); 
        } else {
            this.setState({
                background: true
            }); 
        }
    }

    handleSubmit(label) {
        ReactGA.event({
          category: 'Search',
          action: 'Click',
          label: 'Searched Site'
        });
    }

    componentDidMount() {
        // document.getElementById('searchcInput').focus();
    }

    render() {
        const { size, focusSearchInput } = this.props; 
        const { background } = this.state;

        focusSearchInput && (
            document.getElementById('searchcInput').focus()
            // console.log('Autofocus is true')
        )

        return (
            <form action="/search" className={`search-form ${size || ''}`}>
                <input type="text" className={background ? 'white' : ''} name="term" id="searchcInput" onChange={this.handleChange}/>
                <input type="submit" value="Search" onClick={() => {this.handleSubmit()}}/>
            </form>
        );
    }
}

export default SearchForm;