import React, { Component } from 'react';
import { Link} from 'react-router-dom';
import Select from 'react-select';

import ReactGA from 'react-ga';

function arrayUnique(array) {
    var a = array.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }
    return a;
};

class CaseStudyGridNew extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFiltering: false,

            activeFilters: [],
            activeFiltersCategory: [],
            
            editorialValue: '',
            editorialPlaceholder: 'Editorial Brand',

            advertiserCategoryValue: '',
            advertiserCategoryPlaceholder: 'Advertiser Category',

            adSolutionValue: '',
            adSolutionPlaceholder: 'Ad Solutions',

            platformValue: '',
            platformPlaceholder: 'Platforms',
        };

        this.clearFilters = this.clearFilters.bind(this);

        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }


    clearFilters(event) {
        this.setState({
            isFiltering: false,
            activeFilters: [],
            activeFiltersCategory: [],

            editorialValue: '',
            editorialPlaceholder: 'Editorial Brand',

            advertiserCategoryValue: '',
            advertiserCategoryPlaceholder: 'Advertiser Category',

            adSolutionValue: '',
            adSolutionPlaceholder: 'Ad Solutions',

            platformValue: '',
            platformPlaceholder: 'Platforms',
        });

        // console.log('Active Filters Cleared', this.state.activeFilters);
    }

    handleSelectChange(event) {
        let arrIndex,
            categoryCheck;

        const category = event.category;
        const value = event.value;

        // GA Event
        ReactGA.event({ 
            category: 'Case Study', 
            action: 'Filter', 
            label: 'Case Study Filter - ' + value,
        })

        // console.log('GA Label!', value)

        const currentActiveFilters = this.state.activeFilters,
              currentActiveCategories = this.state.activeFiltersCategory;

        // Check if the selected category already has a value in the state
        categoryCheck = arrayUnique(currentActiveCategories.concat(category));
        
        if (categoryCheck.length === currentActiveCategories.length) {
            // If the category exists, replace the category with the new value
            arrIndex = currentActiveCategories.indexOf(category);
            currentActiveFilters[arrIndex] = value;
        } else {
            // arrIndex = null;
            // if it does't push the category and the value
            currentActiveFilters.push(value);
            currentActiveCategories.push(category);
        }

        // Set the state to Filtering (for visual related element updates)
        // And update the cateogory placeholder text in the select.
        this.setState({
            isFiltering: true,
            [category + 'Placeholder']: event.label,
            [category + 'Value']: event.value,
        });

        console.log('Active Filters', this.state.activeFilters);
    }

    handleClick(label) {
        // console.log('Case Study - ' + label);
        ReactGA.event({ 
            category: 'Case Study', 
            action: 'Click', 
            label: 'Case Study - ' + label,
        })
    }

    render() {
        let editorialItems = [],
            advertiserCategoryItems = [],
            platformItems = [],
            adSolutionItems = [],
            caseStudyItems = [];

        const { grid } = this.props;
        const { activeFilters } = this.state;
        const isFiltering = this.state.isFiltering ? 'filtered' : '';   
        
        grid && (
            grid.caseStudies && grid.caseStudies.length >= 1 && (
                grid.caseStudies.map((item, index) => {
                    if (activeFilters.length === 0) {
                        // If Active Filters Array is empty show all items.
                        caseStudyItems.push(
                            <div className='show' key={index}>
                                
                                <Link className={`case-study__item`}
                                      to={`/case-studies/`+item.fields.slug} 
                                      key={`/case-studies/`+item.fields.slug}
                                      onClick={() => {this.handleClick(item.fields.title)}}>
                                    <img 
                                        src={item.fields.featuredImage.fields.file.url} 
                                        alt={item.fields.featuredImage.fields.file.fileName}
                                    />
                                    <h4>{item.fields.title}: <span>{item.fields.heading}</span></h4>
                                </Link>
                            </div>
                        )
                    } else {
                        if (item.fields.tags) {
                            var mergedArrs = arrayUnique(item.fields.tags.concat(this.state.activeFilters));

                            if (mergedArrs.length === item.fields.tags.length) {
                                // console.log('There is a match of tags')
                                caseStudyItems.push(
                                    <div className='show' key={index}>
                                        { /* console.log('item', item) */ }
                                        <Link className={`case-study__item`}
                                              to={`/case-studies/`+item.fields.slug}
                                              key={`/case-studies/`+item.fields.slug}
                                              onClick={() => {this.handleClick(item.fields.title)}}>
                                            <img 
                                                src={item.fields.featuredImage.fields.file.url} 
                                                alt={item.fields.featuredImage.fields.file.fileName}
                                            />
                                            <h4>{item.fields.title}: <span>{item.fields.heading}</span></h4>
                                        </Link>
                                    </div>
                                )
                            } else {
                               // console.log('There is not a match')
                                // Show the 'No Items meet this criteria message'
                            }
                        }
                    }
                })
            )
        )

        // If Filter Categories Exist, create array of objects for the dropdowns
        if (grid.editorialBrandFilters) {
            grid.editorialBrandFilters.map((subitem, index) => (
                editorialItems.push({value: subitem, label: subitem, category: 'editorial'})
            ))
        }

        if (grid.advertiserCategoryFilters) {
            grid.advertiserCategoryFilters.map((subitem, index) => (
                advertiserCategoryItems.push({value: subitem, label: subitem, category: 'advertiserCategory'})
            ))
        }

        if (grid.platformFilters) {
            grid.platformFilters.map((subitem, index) => (
                platformItems.push({value: subitem, label: subitem, category: 'platform'})
            ))
        }

        if (grid.adSolutionFilters) {
            grid.adSolutionFilters.map((subitem, index) => (
                adSolutionItems.push({value: subitem, label: subitem, category: 'adSolution'})
            ))
        }

        return (
            <div>
                <div className="container--sm">
                    <div className="case-study__category--wrapper">
                        <Select 
                            options={editorialItems} 
                            value={this.state.editorialValue}
                            placeholder={this.state.editorialPlaceholder} 
                            onChange={this.handleSelectChange} 
                            // isClearable={true}
                            isSearchable={false}/>

                        <Select 
                            options={advertiserCategoryItems} 
                            value={this.state.advertiserCategoryValue}
                            placeholder={this.state.advertiserCategoryPlaceholder} 
                            onChange={this.handleSelectChange} 
                            // isClearable={true}
                            isSearchable={false}/>

                        <Select 
                            options={adSolutionItems} 
                            value={this.state.adSolutionValue}
                            placeholder={this.state.adSolutionPlaceholder} 
                            onChange={this.handleSelectChange} 
                            // isClearable={true}
                            isSearchable={false}/>

                        <Select 
                            options={platformItems} 
                            value={this.state.platformValue}
                            placeholder={this.state.platformPlaceholder} 
                            onChange={this.handleSelectChange} 
                            // isClearable={true}
                            isSearchable={false}/>

                        <span className={`case-study__btn--clear ${isFiltering}`} onClick={this.clearFilters}>Clear Filters</span>
                    </div>
                </div>
                <div className="container--no-pad">
                    <section className={`case-study__archive--wrapper ${isFiltering}`}>
                        {caseStudyItems}
                    </section>

                    <div className={`case-study__no-results ${caseStudyItems.length === 0 ? 'visible' : ''}`}>
                        <h3>No Results for this criteria</h3>   
                    </div>
                </div>
            </div>
      );
    }
}

export default CaseStudyGridNew;
