import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';

import Slider from "react-slick";

function SubnavNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SubnavPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

class BrandSubnav extends Component {
  constructor(props) {
      super(props);

      this.state = {
        caretClass: '',
        activeItem: 0,
      };
  }

	render() {
    const pathName = window.location.pathname;

    let subNavCount = 0,
        subnavActive, 
        subnavFinal,
        initalItem,
        subNav = [];

		const { subnavItems, caretClass, isArchive } = this.props;

		subnavItems.forEach(function(item) {
      if (item.fields.url === pathName) {
        subnavActive = subNavCount;
        if (subnavActive === 0) {
          subnavFinal = 0;
        } else {
          // subnavFinal = subnavActive - 1;
          subnavFinal = subnavActive;
        }
      }
      subNavCount++;

      if (isArchive === true) {
        initalItem = 0;
      } else {
        initalItem = subnavFinal;
      }
			
			subNav.push(
					<NavLink to={item.fields.url} 
                   activeClassName='active' 
                   className={`brands-subnav__item ${caretClass}`} 
                   key={item.fields.url} 
                   onClick={() => {ReactGA.event({ category: 'Brands', action: 'Click', label: 'Brands - ' + item.fields.title })}}>
						<img className="brands-subnav__item--active" src={item.fields.image.fields.file.url} alt={item.fields.title}/>
						<img className="brands-subnav__item--inactive" src={item.fields.imageInactiveState.fields.file.url} alt={item.fields.title}/>
					</NavLink>
			);
		});

    var settings = {
      dots: true,
      // infinite: true,
      // speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: initalItem,
      nextArrow: <SubnavNextArrow className="brands-subnav__next" />,
      prevArrow: <SubnavPrevArrow className="brands-subnav__prev" />,
      responsive: [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

		return (
			<div>
				<div className="container">
				  <Slider {...settings} ref={slider => (this.slider = slider)}>
				        {subNav}
				  </Slider>
				</div>
      </div>
		);
	}
}

export default BrandSubnav;
