import React, { Component } from 'react';
import ReactGA from 'react-ga';

import Vimeo from '@u-wave/react-vimeo';

import VimeoModal from '../utility/VimeoModal';
import ImageModal from '../utility/ImageModal';

class ModuleMediaGallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            vimeoID: '131822365',
            imageModalState: false,
        };

        this.openModal = this.openModal.bind(this);
        this.openImageModal = this.openImageModal.bind(this)
    }

    openModal(e) {
        let vimeoVal = e.target.dataset.vimeourl;
        let gaLabel = e.target.dataset.label;
        console.log('Modal', gaLabel);
        e.preventDefault();

        this.setState({
            isOpen: true,
            vimeoID: vimeoVal,
        });
        // console.log('hi');

        ReactGA.event({ 
            category: 'Modal', 
            action: 'Click', 
            label: 'Gallery Item - ' + gaLabel })
    }

    openImageModal(e) {
        let imageURL = e.target.dataset.image;

        e.preventDefault();

        this.setState({
            imageModalState: true,
            imageUrl: imageURL,
        });
    }

    render() {
        const { item } = this.props;

        return (
            <div>
                {item.fields && (
                    <div className={`mod__neg-margin ${item.fields.showHorizontalSeparator ? 'line' : ''}`}>
                        <div>
                            <div className="mod-styles">
                                <h3>{item.fields.heading}</h3>
                            </div>
                            <div className="mod__gallery">
                                {item.fields.mediaAssets.map((subitem, index) => (
                                    <div className="mod__gallery--item" key={index}>
                                        {subitem.fields.assetType && (
                                            <div>
                                                <div className="mod__gallery--wrapper">
                                                    <div className="module__click" data-vimeourl={subitem.fields.vimeoID} data-label={subitem.fields.title} onClick={this.openModal}></div>
                                                    <Vimeo
                                                        video={subitem.fields.vimeoID}
                                                        responsive={true}
                                                        controls={false}/>
                                                </div>
                                                <h4>{subitem.fields.title}</h4>
                                                {subitem.fields.caption && (
                                                    <div dangerouslySetInnerHTML={{ __html: subitem.fields.caption}} />
                                                )}
                                            </div>
                                        )}
                                        {!subitem.fields.assetType && (
                                            <div>
                                                <div className="mod__gallery--wrapper img">
                                                    <div className="module__click" data-image={subitem.fields.asset.fields.file.url} data-label={subitem.fields.title} onClick={this.openImageModal}></div>
                                                    <img src={subitem.fields.asset.fields.file.url} alt={subitem.fields.title} />
                                                </div>
                                                <h4>{subitem.fields.title}</h4>
                                                {subitem.fields.caption && (
                                                    <div dangerouslySetInnerHTML={{ __html: subitem.fields.caption}} />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>

                            <ImageModal 
                                imageUrl={this.state.imageUrl}
                                isOpen={this.state.imageModalState} 
                                onClose={() => this.setState({imageModalState: false})}/>

                            <VimeoModal 
                                channel='vimeo' 
                                isOpen={this.state.isOpen} 
                                videoId={this.state.vimeoID} 
                                onClose={() => this.setState({isOpen: false})}/>
                        </div>
                    </div>
                )}
            </div>
            
        );
    }
}

export default ModuleMediaGallery;