/* ********************************************
 * Module Used for Snippet and Pullquote type
 * ******************************************** */
import React, { Component } from 'react';

class ModuleQuote extends Component {
    render() {
        let layoutClass = '';
        const { body, pullQuote, snippet, layout, contentType, attribution, showHorizontalSeparator } = this.props;

        if (layout === 'Text First 1/3') {
            layoutClass = 'text-single-video-double'; 
        } else if (layout === 'Text First 1/2') {
            layoutClass = 'split';
        } else if (layout === 'Text First 2/3') {
            layoutClass = 'text-double-video-single';
        } else if (layout === 'Text Second 1/3') {
            layoutClass = 'flip  text-single-video-double';
        } else if (layout === 'Text Second 1/2') {
            layoutClass = 'flip split';
        } else if (layout === 'Text Second 2/3') {
            layoutClass = 'flip text-double-video-single';
        } else {
            layoutClass = 'red text-single-video-double';
        }

        return (
            <div className={`mod__mixed mod-styles quote ${layoutClass} ${showHorizontalSeparator ? 'line' : ''}`}>
                <div className="mod-body"> 
                    <div dangerouslySetInnerHTML={{ __html: body }} />
                </div>
                <div className="mod-media">
                    <div className="mod-media__pullquote">
                        {contentType === 'Snippet' && (
                            <blockquote>{snippet}</blockquote>
                        )}

                        {contentType === 'Pull Quote' && (
                            <div>
                                <blockquote className="quote">{pullQuote}</blockquote>
                                <caption>- {attribution}</caption>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default ModuleQuote;