import React, { Component } from 'react';
import Vimeo from '@u-wave/react-vimeo';

import VimeoModal from '../utility/VimeoModal';

class ModuleVideoSnippet extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
	    	isOpen: false,
	    	vimeoID: '131822365',
	    };

	    this.openModal = this.openModal.bind(this)
	}

	openModal (e) {
		let vimeoVal = e.target.dataset.vimeourl;
		e.preventDefault();

    	this.setState({
    		isOpen: true,
    		vimeoID: vimeoVal,
    	});
  	}

	render() {
		const { videoID, caption, snippet } = this.props;

		return (
			<div>
			    <div className="module__text snippet">
			    	<div className="mod-media">
			    		<div className="mod-media__wrapper">
			    			<div className="module__click" data-vimeourl={videoID} onClick={this.openModal}></div>
					    	<Vimeo
		  						video={videoID}
						  		responsive={true}
						  		controls={false}
						  		color="ef2f9f" />
						 </div>
					  	<p className="module__caption">{caption}</p>
			    	</div>

			    	<div className="mod-body">
			    		<h4 className="snippet-text">{snippet}</h4>
			    	</div>
			    </div>

			    <VimeoModal channel='vimeo' isOpen={this.state.isOpen} videoId={this.state.vimeoID} onClose={() => this.setState({isOpen: false})}/>
			</div>
		  );
	}
}

export default ModuleVideoSnippet;