import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Nav from './components/Nav';
import Footer from './components/Footer';
import SearchModal from './components/utility/SearchModal';

import Homepage from './components/Homepage';
import BrandArchive from './components/brands/BrandArchive';
import BrandSingle from './components/brands/BrandSingle';
import BrandStudio from './components/BrandStudio';

import CaseStudyArchive from './components/casestudies/CaseStudyArchive';
import CaseStudySingle from './components/casestudies/CaseStudySingle';

import OpportunityArchive from './components/opportunities/OpportunityArchive';
import OpportunitySingle from './components/opportunities/OpportunitySingle';

import AdsArchive from './components/adsolutions/AdsArchive';
import AdsSingle from './components/adsolutions/AdsSingle';

import Search from './components/Search';
import StandardPage from './components/StandardPage';

import Page404 from './components/utility/Page404';
import ScrollToTop from './components/utility/ScrollToTop';

import CandidLandingPage from './components/CandidLandingPage';

import './main.css';
import '../node_modules/react-modal-video/scss/modal-video.scss';

import { createBrowserHistory } from 'history';

import ReactGA from 'react-ga';

// S3 Env. Routing Rules
const history = createBrowserHistory();

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];

if (path) {
  history.replace(path);
}

// GA Tracking
ReactGA.initialize("UA-158370781-1");

class App extends Component {
  constructor(props) {
      super(props);

      this.state = {
        navIsOpen: false,
        searchIsOpen: false,
        focusSearchInput: false,
      };
  }

  toggleHamburgerNav = () => {
    this.setState({navIsOpen: !this.state.navIsOpen});
  }

  closeHamburgerNav = () => {
    this.setState({
      navIsOpen: false,
      searchIsOpen: false,
    });
  }

  toggleSearchModal = () => {
    if (this.state.searchIsOpen === true) {
      this.setState({
        navIsOpen: false,
      });
    }

    this.setState({
      searchIsOpen: !this.state.searchIsOpen,
      focusSearchInput: !this.state.focusSearchInput,
    });

    // console.log('Search Input', this.state.focusSearchInput)
  }

  render() {
    const { navIsOpen, searchIsOpen, focusSearchInput } = this.state;

    return (
      <div className="app">
        <ScrollToTop />
        <div className={`search-modal__close-btn ${searchIsOpen}`} onClick={this.toggleSearchModal}>
            <span></span>
        </div>
        <Nav 
          toggleSearchModal={this.toggleSearchModal}
          toggleHamburgerNav={this.toggleHamburgerNav}
          closeHamburgerNav={this.closeHamburgerNav}
          navState={navIsOpen}
          searchModalState={searchIsOpen} />

        <div className="app--inner">
          <Switch>
            <Route exact path='/' component={Homepage}/>
            <Route exact path='/brands' component={BrandArchive}/>
            <Route path='/brands/:brandSlug' render={(routeProps) =>
              <BrandSingle key={Date.now()}/>
            }/>
            <Route exact path='/case-studies' component={CaseStudyArchive}/>
            <Route path='/case-studies/:caseStudySlug' render={(routeProps) =>
              <CaseStudySingle key={Date.now()} {...routeProps}/>
            }/>
            <Route path='/courageous' component={BrandStudio}/>
            <Route exact path='/ad-solutions' component={AdsArchive}/>
            <Route path='/ad-solutions/:adsSlug' render={(routeProps) =>
              <AdsSingle key={Date.now()}/>
            }/>
            <Route exact path='/hot-opportunities' component={OpportunityArchive}/>
            <Route path='/hot-opportunities/:hotOpSlug' render={(routeProps) =>
              <OpportunitySingle key={Date.now()}/>
            }/>
            <Route path='/search' component={Search} key={Date.now()}/>
            <Route path="/404" component={Page404}/>
            <Route path="/candid-conversations" component={CandidLandingPage}/>
            <Route path="*" component={StandardPage}/>
            
          </Switch>
        </div>
        <SearchModal 
          focusSearchInput={focusSearchInput}
          toggleSearchModal={this.toggleSearchModal}
          searchModalState={searchIsOpen}/>
        <Footer/>
      </div>
    );
  }
}

export default App;