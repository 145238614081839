import React, { Component } from 'react';

class ModuleTextImage extends Component {
	render() {
		let layoutClass = '',
		    redBarClass = '';
		const { image, caption, layout, bodyCopy, hideRedBar, showHorizontalSeparator, specialcss } = this.props;
		
		if (hideRedBar) {
		  redBarClass = 'hide-bar';
		}

		if (layout === 'Text First 1/3') {
			layoutClass = 'text-single-video-double'; 
		} else if (layout === 'Text First 1/2') {
			layoutClass = 'split';
		} else if (layout === 'Text First 2/3') {
			layoutClass = 'text-double-video-single';
		} else if (layout === 'Text Second 1/3') {
			layoutClass = 'flip  text-single-video-double';
		} else if (layout === 'Text Second 1/2') {
			layoutClass = 'flip split';
		} else if (layout === 'Text Second 2/3') {
			layoutClass = 'flip text-double-video-single';
		} else {
			layoutClass = 'red text-single-video-double';
		}
		
		return (
			<div className={`mod__mixed mod-styles ${layoutClass} ${showHorizontalSeparator ? 'line' : ''}  ${specialcss}`}>
		    	<div className="mod-body">
		    		<div dangerouslySetInnerHTML={{ __html: bodyCopy }} />
		    	</div>
		    	<div className="mod-media">
		    		<div className={`mod-media__wrapper ${redBarClass}`}>
					  	<img src={image} alt={caption}/>
					 </div>
					<div className="mod__caption">
					 	<p><b><i>{caption}</i></b></p>
					</div>
		    	</div>
		    </div>
		  );
	}
}

export default ModuleTextImage;