import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import BrandSubnav from './BrandSubnav';

import ModuleFullAsset from '../modules/ModuleFullAsset';
import ModuleQuote from '../modules/ModuleQuote';
import ModuleTextVideo from '../modules/ModuleTextVideo';
import ModuleTextImage from '../modules/ModuleTextImage';
import ModuleFastFacts from '../modules/ModuleFastFacts';
import ModuleText from '../modules/ModuleText';
import ModuleDoubleAsset from '../modules/ModuleDoubleAsset';

import ReactGA from 'react-ga';

let singleContent;

var contentful = require('contentful');

var client = contentful.createClient({
  space: 'purcre4qggd1',
  // environment: 'staging',
  accessToken: 'vdIxkgnr7AFvqwzg7XFaqNaI9K4LmXOwzuvAquQOxok'
});

class BrandSingle extends Component {
	constructor(props) {
        super(props);

        this.state = {
        	activeSlideURL: '',
        	apiFail: false,
        	headline: '',
        	introCopy: '',
        	brandModules: [],
        	subNavItems: [],
        	fastFacts: [],
        	fastFactsStats: [],
        	fastFactsHeadline: '',
        	fastFactsSource: '*Above is sourced from October 2019 data. Need to update.)171MM & #1 in Reach] SOURCE: (1) comScore X-Media Total view Audience Analysis Report. Live+7. Period: Jan-June 2019 Average Monthly, P2+. Television numbers are based on broadcast month, Digital and mobile are based on calendar month. Reach (000). (2) Nielsen Live+Same, 8/25/19, Rank Among Cable Films In Prime (7p-11p) Based On P25-54 W/ 4+ Years College; Ranked On Index. (3) Nielsen Live+Same, 8/25/19, Rank Based On AA (000) 9pm-11pm Time Period. (4) Nielsen Based On Live+7 Most Current, August 2019. (5) comScore Media Metrix Multiplatform, July 2019, Total Multiplatform Uniques based on Desktop Persons 2+ and Total Mobile Persons 13+, Millennials based on Persons 18-34. (6) comScore Video Metrix Multiplatform, June 2019, Total Audience – Desktop Persons 2+ and Total Mobile Persons 18+ and OTT Persons 2+. (7) comScore Mobile Metrix, July 2019, Total Mobile Persons 13+. [Lift in attention and recall] SOURCE: MediaScience / CNN Research, The Power of News Study. December 2018. N=307.',
        	caretClass: '',
        	caseStudies: [],
        };
	}

	componentDidMount() {
		singleContent = window.location.pathname;
		singleContent = singleContent.substring(singleContent.lastIndexOf('/')+1);

	  client.getEntries({
		'content_type': 'brandPage',
	    'fields.slug': singleContent,
	   	'include': 10
	  }).catch(error => {
	    console.log(error);
	  }).then(data => {
    	if (data) {
    		// IF API CALL RETURNS 0 INSTEAD OF A JS ERROR 
    		// WE SHOULD THROW A 404
    		if (data.total !== 0) {
    			// console.log('Test', data);

    			// Set page title
    			document.title = data.items[0].fields.title + ' | Warner Bros. Discovery';

                // Top-level Page Tracking
                ReactGA.set({ page: '/brands/' + singleContent }); // Update the user's current page
                ReactGA.pageview('/brands/' + singleContent);

	    		this.setState({
                    title: data.items[0].fields.title,
	    			activeSlideURL: singleContent,
	    			headline: data.items[0].fields.heading,
	    			introCopy: data.items[0].fields.intro,
	    			brandModules: data.items[0].fields.modules,
	    			subNavItems: data.items[0].fields.brandCarousel.fields.slides,
	    			caseStudies: data.items[0].fields.caseStudies,
	    			hotOpportunities: data.items[0].fields.hotOpportunities,
	    			fastFacts: data.items[0].fields.fastFacts,
	    		});
    		} else {
    			this.setState({
    				apiFail: true,
    			})
    		}
    		
    	}
    });

	    // Scroll
	window.addEventListener('scroll', () => {
		let activeClass = '';
		if(window.scrollY <= 20){
			activeClass = '';
		} else {
			activeClass = 'hide-caret';
		}

		this.setState({ 
			caretClass: activeClass 
		});
	  });
	}

	redirectPage = () => {
		if (this.state.apiFail) {
			return <Redirect to='/404' />
		}
	}

	render() {
		let modulesArr = [],
            lineBreak = [];

		const { title,
                headline, 
				introCopy, 
				brandModules, 
				subNavItems, 
				caseStudies, 
				hotOpportunities, 
				fastFacts,
				caretClass, 
				activeSlideURL } = this.state;

		// console.log('Fast', fastFacts.fields.title);

		// Loop through brand modules
        if (brandModules) {
    	 	brandModules.forEach(function(item, index) {
    	 		if (item.sys.contentType.sys.id === "fullWidthAssetModule") {
    	 			if (item.fields.assetType) {
    	 				// Full Width Video
    	 				modulesArr.push(
    		 				<ModuleFullAsset
                                key={index}
    		 					assetType={item.fields.assetType}
    		 					videoID={item.fields.vimeoID}
    		 					caption={item.fields.caption} />
    		 			);
    	 			} else {
    	 				// Full Width Image
    	 				modulesArr.push(
    	 					<ModuleFullAsset
                                key={index}
    		 					assetType={item.fields.assetType}
    		 					title={item.fields.title}
    		 					image={item.fields.asset.fields.file.url}
    		 					caption={item.fields.caption} />
    	 				);
    	 			}
    	 		} else if (item.sys.contentType.sys.id === 'textModule') {
                    modulesArr.push(
                        <ModuleText
                            key={index}
                            position={item.fields.position}
                            width={item.fields.width}
                            bodyCopy={item.fields.bodyCopy}
                        />
                  
                    )
                } else if (item.sys.contentType.sys.id === "textPlusModule") {
    	 			if (item.fields.sidebarContentType === 'Video') {
                        console.log('Vid Text+', item);
    						modulesArr.push(
    							<ModuleTextVideo
                                    key={index}
                                    hideRedBar={item.fields.hideRedBar}
    								showHorizontalSeparator={item.fields.showHorizontalSeparator}
    								videoID={item.fields.vimeoID}
    								bodyCopy={item.fields.body}
    								caption={item.fields.caption}
    								layout={item.fields.layout}
    							/>
    						)
    				} else if (item.fields.sidebarContentType === 'Image') {
                        modulesArr.push(
                            <ModuleTextImage
                                key={index}
                                showHorizontalSeparator={item.fields.showHorizontalSeparator}
                                hideRedBar={item.fields.hideRedBar}
                                layout={item.fields.layout}
                                image={item.fields.image.fields.file.url}
                                caption={item.fields.caption}
                                bodyCopy={item.fields.body} />
                        )
                    } else if (item.fields.sidebarContentType === 'Snippet' || item.fields.sidebarContentType === 'Pull Quote') {
    	 				modulesArr.push(
    	 					<ModuleQuote
                                key={index}
    	 						showHorizontalSeparator={item.fields.showHorizontalSeparator}
    	 						contentType={item.fields.sidebarContentType}
    	 						layout={item.fields.layout}
    							body={item.fields.body}
    							snippet={item.fields.snippet}
    							pullQuote={item.fields.pullQuote}
    							attribution={item.fields.attribution}
    						/>
    					);
    	 			}
    	 		} else if (item.sys.contentType.sys.id === "doubleAssetModule" ) {
    				modulesArr.push(
    					<ModuleDoubleAsset
                            key={index}
    						item={item} />
    				);
    			}
    		});
        }

        if (caseStudies || hotOpportunities) {
            lineBreak.push(
                <div className="brand-single--no-padding">
                    <div className="brand-single__wrapper no-padding">
                        <div className="container">
                            <hr className="fast-facts__line-break no-top-margin"/>
                        </div>
                    </div>
                </div>
            )
        }
		 	  
		return (
			<div>
				{this.redirectPage()}
                {subNavItems[0] && (
                    subNavItems[0].sys.contentType.sys.id === "carouselSlide" && (
				        <div className={`brands-subnav__single ${caretClass}`}>
                            <div>                
                                <BrandSubnav subnavItems={subNavItems}
            						caretClass={caretClass}
            						activeSlideURL={activeSlideURL}/>
                            </div>
                        </div>
                    )
                )}

			    <div className="brand-single">
			    	<div className="brand-single__wrapper">
			    		<div className="container">
						    <h1 className="brand-single__headline">{headline}</h1>
						    <div className="brand-single__intro" dangerouslySetInnerHTML={{ __html: introCopy}}/>
						   	{modulesArr}
						</div>
					</div>
			    </div>

			    {fastFacts && (
			    	<ModuleFastFacts item={fastFacts}/>
			    )}

                {lineBreak && (
                    lineBreak
                )}

			    <div className="brand-single--no-padding">
			    	<div className="brand-single__wrapper no-top-padding">
			    		<div className="container">
			    			{/* Case Studies / Hot Opportunities  */}
			    			{caseStudies && (
			    				<div className="fast-facts__section--blocks">
					    			<h3 className="fast-facts__headline--no-line">Case Studies</h3>
					    			<Link to="/case-studies/" className="hot-op__link" onClick={() => {ReactGA.event({ category: 'Brands', action: 'Click', label: 'Brands - See All Case Studies'})}}>See All</Link>
					    			<section className="case-study__archive--wrapper brands">
						    			{caseStudies.map((item, index) => (
											<Link className="case-study__archive--item" to={`/case-studies/`+item.fields.slug} key={item.fields.slug} onClick={() => {ReactGA.event({ category: 'Brands', action: 'Click', label: title + ' - ' + item.fields.title})}}>
												{item.fields.featuredImage && (
													<img src={item.fields.featuredImage.fields.file.url}
						    						 alt={item.fields.featuredImage.fields.file.fileName}/>
												)}
						    					<h4>{item.fields.title}: <span>{item.fields.heading}</span></h4>
											</Link>
						    			))}
						    		</section>
				    			</div>
			    			)}
			    			
			    			{hotOpportunities && (
					    		<div className="fast-facts__section--blocks">
					    			<h3 className="fast-facts__headline--no-line">Hot Opportunities</h3>
					    			<Link to="/hot-opportunities/" className="hot-op__link" onClick={() => {ReactGA.event({ category: 'Brands', action: 'Click', label: 'Brands - See All Opportunities'})}}>See All</Link>
					    			<section className="case-study__archive--wrapper brands">
				    					{hotOpportunities.map((item, index) => (
											<div className="case-study__archive--item" key={index}> 
												<a href={item.fields.url} target="_blank" rel="noopener noreferrer" onClick={() => {ReactGA.event({ category: 'Brands', action: 'Click', label: title + ' - ' + item.fields.heading})}}>
													{item.fields.image && (
														<img src={item.fields.image.fields.file.url}
						    						 	alt={item.fields.heading}/>
													)}
                                                </a>
                                                <a href={item.fields.url} target="_blank" rel="noopener noreferrer" onClick={() => {ReactGA.event({ category: 'Brands', action: 'Click', label: title + ' - ' + item.fields.heading})}}>
							    					<h4>{item.fields.heading}</h4>
							    					<h5>{item.fields.subheading}</h5>
                                                </a>
							    				<div dangerouslySetInnerHTML={{ __html: item.fields.excerpt}}/>

						    					{item.fields.materialLink && (
								                    <a href={item.fields.materialLink} target="_blank" className="hot-op__item--btn" rel="noopener noreferrer">Materials</a>
								                )}
						    					
											</div>
					    				))}
					    			</section>
					    		</div>
				    		)}
			    		</div>
			    	</div>
			    </div>
		    </div>
		  );
	}
}

export default BrandSingle;
