import React, { Component } from 'react';
import ReactGA from 'react-ga';

class OpportunityCarouselItem extends Component {
    render() {
        const { url, key, image, heading, subheading, description, materialLink} = this.props;

        return (
            <div className="hot-op__item" key={key}>
                <a href={url} target="_blank" rel="noopener noreferrer" onClick={() => {ReactGA.event({ category: 'Opportunities', action: 'Click', label: heading})}}>
                    <img src={image} alt={heading}/>
                </a>
                
                <a href={url} target="_blank" rel="noopener noreferrer" onClick={() => {ReactGA.event({ category: 'Opportunities', action: 'Click', label: heading})}}>
                    <h3>{heading}</h3>
                </a>
                <h4>{subheading}</h4>
                <div dangerouslySetInnerHTML={{ __html: description}}/>
                {materialLink && (
                    <a href={materialLink} target="_blank" className="hot-op__item--btn" rel="noopener noreferrer" onClick={() => {ReactGA.event({ category: 'Opportunities', action: 'Click', label: 'Materials - ' + heading})}}>Materials</a>
                )}
                
            </div>
          );
    }
}

export default OpportunityCarouselItem;
