import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import BrandSubnav from './BrandSubnav';

var contentful = require('contentful')

var client = contentful.createClient({
  space: 'purcre4qggd1',
  // environment: 'staging',
  accessToken: 'vdIxkgnr7AFvqwzg7XFaqNaI9K4LmXOwzuvAquQOxok'
});
 
class BrandArchive extends Component {
    constructor(props) {
        super(props);

        this.state = {
            headline: '',
            intro: [],
            subNavModules: [],
            contentModules: [],
            platformItems: [],
        };
    }

    componentDidMount() {
        document.title = 'Our Brands | Warner Bros. Discovery';

        // Top-Level page tracking
        ReactGA.set({ page: '/brands' }); // Update the user's current page
        ReactGA.pageview('/brands');

        client.getEntries({
            content_type: 'archiveTemplate',
            'fields.slug': 'brands',
            'include': 10
        }).then(data => {
            if (data) {
                let pathData = data.items[0].fields;
                console.log(data.items[0])
                this.setState({
                    desktopBG: pathData.heroBgImage,
                    headline: pathData.heading,
                    intro: pathData.intro,
                    subNavModules:  pathData.modules[0].fields.slides,
                    contentModules: pathData.modules,
                });
            }
        });

        // Get Platforms
        client.getEntries({
            'content_type': 'platform',
            'include': 10,
            'order': 'fields.displayOrder',
        }).then(data => {
            if (data) {
                // console.log('works');
                this.setState({
                    platformItems: data.items,
                });

                console.log('test', data.items);

                data.items.forEach(function(item, index) {
                    console.log(item)
                });
            }
        });
    }

    render() {
        let subNav = [],
            modulesArr = [],
            platformsArr = [];

        const { headline, intro, subNavModules, contentModules, desktopBG, platformItems } = this.state;

        subNavModules.forEach(function(item) {
            subNav.push(
                <div className="brands__subnav--item" key={item.fields.url}>
                    <Link to={item.fields.url}>
                        <h3>{item.fields.title}</h3>
                        <p>{item.fields.url}</p>
                        <img src={item.fields.image.fields.file.url} alt={item.fields.title}/>
                        <img src={item.fields.imageInactiveState.fields.file.url} alt={item.fields.title}/>
                    </Link>
                </div>
            );
        });

        platformItems.forEach(function(item) {
            platformsArr.push(
                <div className="platform__item">
                    <div className="platform__item--top">
                        <img src={item.fields.icon.fields.file.url} alt={`Icon - ${item.fields.title}`}/>
                    </div>
                    <div className="platform__item--bottom">
                        <h5>{item.fields.title}</h5>
                        {item.fields.items.map((subItem, index) => (
                            <span key={index}>{subItem}</span>
                        ))}
                    </div>
                </div>
            )
        });
       
        contentModules.forEach(function(item) {
            // SECTION: PLATFORM LIST
            if (item.sys.contentType.sys.id === 'fastFacts') {
                modulesArr.push(
                    <section className="brands__section--reach">
                        <div className="fast-facts__section--headline brand-archive">
                            <h2><span>{item.fields.heading}</span></h2>
                        </div>

                        <div className="container">
                            {/* <h2 className="brands-reach__headline red"><span>{item.fields.heading}</span></h2> */}
                            {item.fields.stats[0].reach_highlights.length >= 1 && (
                                <div>
                                    {item.fields.stats[0].show_reach_highlights_subhead && (
                                        <h3 className="fast-facts__headline">
                                            {item.fields.reachHighlightsCustomSubhead && (
                                                <span>{item.fields.reachHighlightsCustomSubhead}</span>
                                            )}
                                            {!item.fields.reachHighlightsCustomSubhead && (
                                                <span>Reach Highlights</span>
                                            )}
                                        </h3>
                                    )}
                                    <div className="fast-facts__section">
                                        {item.fields.stats[0].reach_highlights.map((subitem, index) => (
                                            <div className="fast-facts__item" key={index}>
                                                <p className="fast-facts__item--top">{subitem.value}</p>
                                                <p className="fast-facts__item--bottom">{subitem.label}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {item.fields.stats[0].audience_overview.length >= 1 && (
                                <div>
                                    {item.fields.stats[0].show_audience_overview_subhead && (
                                        <h3 className="fast-facts__headline">
                                            {item.fields.audienceOverviewCustomSubhead && (
                                                <span>{item.fields.audienceOverviewCustomSubhead}</span>
                                            )}
                                            {!item.fields.audienceOverviewCustomSubhead && (
                                                <span>Audience Oveview</span>
                                            )}
                                        </h3>
                                    )}
                                    <div className="fast-facts__section">
                                        {item.fields.stats[0].audience_overview.map((subitem, index) => (
                                            <div className="fast-facts__item" key={index}>
                                                <p className="fast-facts__item--top">{subitem.value}</p>
                                                <p className="fast-facts__item--bottom">{subitem.label}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {item.fields.stats[0].audience_highlights.length >= 1 && (
                                <div>
                                    {item.fields.stats[0].show_audience_highlights_subhead && (
                                        <h3 className="fast-facts__headline">
                                            {item.fields.audienceHighlightsCustomSubhead && (
                                                <span>{item.fields.audienceHighlightsCustomSubhead}</span>
                                            )}
                                            {!item.fields.audienceHighlightsCustomSubhead && (
                                                <span>Audience Highlights</span>
                                            )}
                                        </h3>
                                    )}
                                    <div className="fast-facts__section--single">
                                        {item.fields.stats[0].audience_highlights.map((subitem, index) => (
                                            <div className="fast-facts__item" key={index}>
                                                <p className="fast-facts__item--top">{subitem.value}</p>
                                                <p className="fast-facts__item--bottom">{subitem.label}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            
                            <div className="fast-facts__source" dangerouslySetInnerHTML={{ __html: item.fields.sourceCopy }}/>

                        </div>
                    </section>
                );
            }
        })

        let desktopHeaderStyles = {};

        desktopBG && (
            desktopHeaderStyles = {
                backgroundImage: `url(${desktopBG.fields.file.url})`,
            }
        )

        return (
            <div>
                <section className="section__intro">
                    {desktopBG && (
                        <div className="section__intro--bg-desktop"
                            style={desktopHeaderStyles}></div>
                    )}
                    <div className="container">
                        <h1 className="section__intro--headline">{headline}</h1>
                        <div className="section__intro--body" dangerouslySetInnerHTML={{ __html: intro }}/>
                        <div className="brands-subnav__archive">
                            <BrandSubnav subnavItems={subNavModules} isArchive={true}/>
                        </div>
                    </div>
                </section>

                {modulesArr}

                {platformsArr.length >= 1 && (
                    <section className="brands__section--platforms">
                        <div className="fast-facts__section--headline red">
                            <h2><span>Our Platforms</span></h2>
                        </div>

                        <div className="container">
                            <div className="platform__wrapper">
                                {platformsArr}
                            </div>
                        </div>
                    </section>
                )}

            </div>
          );
    }
}

export default BrandArchive;
