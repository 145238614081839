import React, { Component } from 'react';
import ReactGA from 'react-ga';

import CaseStudyGridNew from './CaseStudyGridNew';

var contentful = require('contentful')

var client = contentful.createClient({
  space: 'purcre4qggd1',
  // environment: 'staging',
  accessToken: 'vdIxkgnr7AFvqwzg7XFaqNaI9K4LmXOwzuvAquQOxok'
});

class CaseStudyArchive extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
	    	headline: '',
	    	intro: '',
	    	grid: [],
	    };
	}
	componentDidMount() {
		// Set Page Title
		document.title = 'Case Studies | Warner Bros. Discovery';

		// Top-level Page Tracking
		ReactGA.set({ page: '/case-studies' });
  	    ReactGA.pageview('/case-studies');

		// Query Page Content
	    client.getEntries({
			content_type: 'archiveTemplate',
	    	'fields.slug': 'case-studies',
	    	'include': 10,
	    }).then(data => {
	    	if (data) {
	    		// console.log('Case Study', data);
	    		let pathData = data.items[0].fields;
	    		this.setState({
	    			desktopBG: pathData.heroBgImage,
	    			headline: pathData.heading,
	    			intro: pathData.intro,
	    			grid: pathData.modules[0].fields,
	    		});
	    	}
	    });
	}

	render() {
		const {headline, intro, grid, desktopBG} = this.state;

		let desktopHeaderStyles = {};

        desktopBG && (
            desktopHeaderStyles = {
                backgroundImage: `url(${desktopBG.fields.file.url})`,
            }
        )

		return (
	    <div>
	    	<section className="section__intro">
	    		{desktopBG && (
                    <div className="section__intro--bg-desktop"
                        style={desktopHeaderStyles}></div>
                )}
		    	<div className="container">
			    	<h1 className="section__intro--headline">{headline}</h1>
			    	<div className="section__intro--body" dangerouslySetInnerHTML={{ __html: intro }}/>
			    </div>
			</section>
				
			<CaseStudyGridNew grid={grid} />
			
	    </div>
	  );
	}
}

export default CaseStudyArchive;
