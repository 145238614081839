import React, { Component } from 'react';

import AdsNavItem from './AdsNavItem';

var contentful = require('contentful')

var client = contentful.createClient({
  space: 'purcre4qggd1',
  environment: 'staging',
  accessToken: 'vdIxkgnr7AFvqwzg7XFaqNaI9K4LmXOwzuvAquQOxok'
});

class AdsNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navItems: [],
        };
    }

    componentDidMount() {
        client.getEntries({
            'content_type': 'adSolution',
            'include': 10,
            'order': 'fields.displayOrder',
        }).then(data => {
            if (data) {
                // console.log('Nav', data);
                this.setState({
                    navItems: data.items,
                });
            }
        });
    }

    checkActive() {
        console.log('checkingActive!');
    }

    render() {
        let navItemsArr = [];
        const { navItems } = this.state;
        const { showDescription, classes } = this.props;

        navItems.map((item, index) => (
            // Print all Ad Solutions who's been deemed a "Top Level Category"
            item.fields.isTopLevelCategory && (
                item.fields.subcategories ? (
                // If the item has subcategories, use the first subcat's slug.
                    navItemsArr.push(
                        <AdsNavItem
                            key={index}
                            parentLink={item.fields.slug}
                            link={item.fields.subcategories[0].fields.slug}
                            title={item.fields.title}
                            description={showDescription ? item.fields.shortDescription : null}
                            iconActive={item.fields.iconActiveState.fields.file.url}
                            iconInactive={item.fields.icon.fields.file.url}
                        />
                    )   
                ) : (
                // If Item does not have a subcategory, use it's slug.
                    navItemsArr.push(
                        <AdsNavItem
                            key={index}
                            link={item.fields.slug}
                            title={item.fields.title}
                            description={showDescription ? item.fields.shortDescription : null}
                            iconActive={item.fields.iconActiveState.fields.file.url}
                            iconInactive={item.fields.icon.fields.file.url}
                        />
                    )
                )
            )
        ));

        return (
            <section className={`section__ads-nav ${classes}`}>
                <div className="container--ads">
                    <nav className="ads-nav__flex">
                        {navItemsArr} 
                    </nav>
                </div>
            </section>    
          );
    }
}

export default AdsNav;
