import React, { Component } from 'react';
import Vimeo from '@u-wave/react-vimeo';

/*
import VimeoModal from '../utility/VimeoModal';
<div className="module__click" data-vimeourl={videoID} onClick={this.openModal}></div>
<VimeoModal channel='vimeo' color={'#c00000'} isOpen={this.state.isOpen} videoId={videoID} onClose={() => this.setState({isOpen: false})}/>
*/

class ModuleTextVideo extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
	    	isOpen: false,
	    	// vimeoID: '131822365',
	    };

	    // this.openModal = this.openModal.bind(this)
	}

	/* openModal (e) {
		let vimeoVal = e.target.dataset.vimeourl;
		e.preventDefault();

    	this.setState({
    		isOpen: true,
    		vimeoID: vimeoVal,
    	});
  	}*/

	render() {
		let layoutClass = '',
			redBarClass = '';

		const { videoID, caption, layout, bodyCopy, hideRedBar, showHorizontalSeparator } = this.props;
		
		if (hideRedBar) {
		  redBarClass = 'hide-bar';
		}

		if (layout === 'Text First 1/3') {
			layoutClass = 'text-single-video-double'; 
		} else if (layout === 'Text First 1/2') {
			layoutClass = 'split';
		} else if (layout === 'Text First 2/3') {
			layoutClass = 'text-double-video-single';
		} else if (layout === 'Text Second 1/3') {
			layoutClass = 'flip  text-single-video-double';
		} else if (layout === 'Text Second 1/2') {
			layoutClass = 'flip split';
		} else if (layout === 'Text Second 2/3') {
			layoutClass = 'flip text-double-video-single';
		} else {
			layoutClass = 'red text-single-video-double';
		}

		return (
		    <div className={`mod__mixed mod-styles ${layoutClass} ${showHorizontalSeparator ? 'line' : ''}`}>
		    	<div className="mod-body">
		    		<div dangerouslySetInnerHTML={{ __html: bodyCopy }} />
		    	</div>
		    	<div className="mod-media">
		    		<div className={`mod-media__wrapper ${redBarClass}`}>
					  	<Vimeo
			                video={videoID}
			                responsive={true}
			                showTitle={false}
			                showPortrait={false}
			                showByline={false}
			                color='c00000'/>
					 </div>
					<div className="mod__caption">
					 	<p><b><i>{caption}</i></b></p>
					</div>
		    	</div>
		    </div>
		  );
	}
}

export default ModuleTextVideo;