import React, { Component } from 'react';
import Vimeo from '@u-wave/react-vimeo';

class ModuleFullAsset extends Component {
  render() {
    let redBarClass = '';
    const { image, title, caption, assetType, videoID, hideRedBar, heading } = this.props;

    if (hideRedBar) {
      redBarClass = 'hide-bar';
    }

    return (
      <div className={`mod__full ${redBarClass}`}>
        {heading && (
          <div className="mod__full--header" dangerouslySetInnerHTML={{ __html: heading}}/>
        )}
        {assetType === true && (  
          <div>
            <div className="mod__full--inner">
              <Vimeo
                video={videoID}
                responsive={true}
                showTitle={false}
                showPortrait={false}
                showByline={false}
                color='c00000'/>
            </div>
            <div className="mod__caption" dangerouslySetInnerHTML={{ __html: caption }} />
          </div>
        )}
        {assetType === false && (
          <div>  
            <div className="mod__full--inner">    
              <img src={image} alt={title}/>
            </div>
            <div className="mod__caption" dangerouslySetInnerHTML={{ __html: caption }} />
          </div>
        )}
      </div>
    );
  }
}

export default ModuleFullAsset;