import React, { Component } from 'react';

class EditorialCalendar extends Component {
    render() {
        const { item } = this.props;

        let monthsArr = [];

        if (item.fields.month1Headline !== undefined && item.fields.january !== undefined) {
            monthsArr.push({header: item.fields.month1Headline, content: item.fields.january});
        }

        if (item.fields.month2Headline !== undefined && item.fields.february !== undefined) {
            monthsArr.push({header: item.fields.month2Headline, content: item.fields.february});
        }

        if (item.fields.month3Headline !== undefined && item.fields.march !== undefined) {
            monthsArr.push({header: item.fields.month3Headline, content: item.fields.march});
        }

        if (item.fields.month4Headline !== undefined && item.fields.april !== undefined) {
            monthsArr.push({header: item.fields.month4Headline, content: item.fields.april});
        }

        if (item.fields.month5Headline !== undefined && item.fields.may !== undefined) {
            monthsArr.push({header: item.fields.month5Headline, content: item.fields.may});
        }

        if (item.fields.month6Headline !== undefined && item.fields.june !== undefined) {
            monthsArr.push({header: item.fields.month6Headline, content: item.fields.june});
        }

        if (item.fields.month7Headline !== undefined && item.fields.july !== undefined) {
            monthsArr.push({header: item.fields.month7Headline, content: item.fields.july});
        }

        if (item.fields.month8Headline !== undefined && item.fields.august !== undefined) {
            monthsArr.push({header: item.fields.month8Headline, content: item.fields.august});
        }

        if (item.fields.month9Headline !== undefined && item.fields.september !== undefined) {
            monthsArr.push({header: item.fields.month9Headline, content: item.fields.september});
        }

        if (item.fields.month10Headline !== undefined && item.fields.october !== undefined) {
            monthsArr.push({header: item.fields.month10Headline, content: item.fields.october});
        }

        if (item.fields.month11Headline !== undefined && item.fields.november !== undefined) {
            monthsArr.push({header: item.fields.month11Headline, content: item.fields.november});
        }

        if (item.fields.month12Headline !== undefined && item.fields.december !== undefined) {
            monthsArr.push({header: item.fields.month12Headline, content: item.fields.december});
        }

        let finalLayout = [];
        let currentRow = [];

        for (var i = 0; i < monthsArr.length; i++) {
            currentRow.push(<div class="calendar__row--col">
                <h4>{ monthsArr[i].header }</h4>
                <div dangerouslySetInnerHTML={{ __html: monthsArr[i].content }}/>
            </div>);

            if ((i+1) % 3 === 0) {
                finalLayout.push(<div className="calendar__row"> {currentRow }</div>);
                currentRow = [];
            }

            if (i === monthsArr.length - 1 && (i+1) % 3 !== 0) {
                finalLayout.push(<div className="calendar__row"> {currentRow }</div>);
            }
        }

        return (
            <div className="container">
                <div className="hot-op__section calendar">
                    <h2 className="line">{item.fields.heading}</h2>
                    { finalLayout }
                </div>
            </div>
        );
    }
}

export default EditorialCalendar;
