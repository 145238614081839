import React, { Component } from 'react';

class AdsHeader extends Component {
    render() {
        const { headline, introCopy, desktopBG, size } = this.props;
        let desktopHeaderStyles = {};

        desktopBG && (
            desktopHeaderStyles = {
                backgroundImage: `url(${desktopBG.fields.file.url})`,
            }
        )

        return (      
            <section className={`section__intro ${size || ''}`}>
                {desktopBG && (
                    <div className="section__intro--bg-desktop"
                        style={desktopHeaderStyles}></div>
                )}
                <div className="container">
                    <h1 className="section__intro--headline">{headline || ''}</h1>
                    <div className="section__intro--body" dangerouslySetInnerHTML={{ __html: introCopy || '' }}/>
                </div>
            </section>
          );
    }
}

export default AdsHeader;
