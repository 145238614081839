import React, { Component } from 'react';
import {Link, NavLink} from 'react-router-dom';

class AdsArchive extends Component {

    render() {
        const { type, assets } = this.props;

        return (
            <section className="ads-related">
                {type === 'Case Studies' && (
                    <div className="container">

                        <hr className="margin-bottom"/>

                        <h3 className="fast-facts__headline--no-line">{type}</h3>
                        <Link to="/case-studies" className="hot-op__link">See All</Link>
                    
                        
                        <div className="mod__gallery">
                            {assets.map((item, index) => (
                                <NavLink className="mod__gallery--item"
                                      to={`/case-studies/${item.fields.slug}`} 
                                      key={`/case-studies/${item.fields.slug}`}>
                                    <img 
                                        src={item.fields.featuredImage.fields.file.url} 
                                        alt={item.fields.featuredImage.fields.file.fileName}
                                    />
                                    <h4>{item.fields.title}: <span>{item.fields.heading}</span></h4>
                                </NavLink>
                            ))}
                        </div>
                    </div>
                )}
                {type === 'Hot Opportunities' && (
                    <div className="container">
                       
                        <h3 className="fast-facts__headline--no-line">{type}</h3>
                        <Link to="/hot-opportunities/" className="hot-op__link">See All</Link>
                        
                        <div className="mod__gallery">
                            {assets.map((item, index) => (
                                <div className="mod__gallery--item" key={item.fields.url}>
                                    <a href={item.fields.url} target="_blank" rel="noopener noreferrer">
                                        <img 
                                            src={item.fields.image.fields.file.url} 
                                            alt={item.fields.image.fields.file.fileName}
                                        />
                                        <h4>{item.fields.heading}</h4>
                                        <h5>{item.fields.subheading}</h5>
                                    </a>
                                    <div dangerouslySetInnerHTML={{ __html: item.fields.excerpt}}/>
                                    {item.fields.materialLink && (
                                        <a href={item.fields.materialLink} target="_blank" className="hot-op__item--btn" rel="noopener noreferrer">Materials</a>
                                    )}
                                </div>
                            ))}
                        </div>

                    </div>
                )}
            </section>
          );
    }
}

export default AdsArchive;