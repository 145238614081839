import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import ReactGA from 'react-ga';

import OpportunityModules from './OpportunityModules';
import OpportunityHeader from './OpportunityHeader';

let singleContent;

var contentful = require('contentful')

var client = contentful.createClient({
  space: 'purcre4qggd1',
  // environment: 'staging',
  accessToken: 'vdIxkgnr7AFvqwzg7XFaqNaI9K4LmXOwzuvAquQOxok'
});

class OpportunitySingle extends Component {
    constructor(props) {
        super(props);

        this.state = {
            apiFail: false,
            headline: '',
            mainItems: [],
            modules: []
        };
    }

    componentDidMount() {
        singleContent = window.location.pathname;
        singleContent = singleContent.substring(singleContent.lastIndexOf('/')+1);

        // document.title = 'Single Opportunity | Warner Media';

        client.getEntries({
            'content_type': 'opportunityCarouselPage',
            'fields.slug': singleContent,
            'include': 10
        }).then(data => {
            if (data) {
                // IF API CALL RETURNS 0 INSTEAD OF A JS ERROR 
                // WE SHOULD THROW A 404
                if (data.total !== 0) {
                   
                    document.title = data.items[0].fields.heading + ' | Warner Bros. Discovery';

                    // Top-level Page Tracking
                    ReactGA.set({ page: '/hot-opportunities/' + singleContent });
                    ReactGA.pageview('/hot-opportunities/' + singleContent);

                    this.setState({
                        headline: data.items[0].fields.heading,
                        mainItems: data.items[0].fields.carousel.fields.slides,
                        modules: data.items[0].fields.moreHotOpportunities,
                    });
                } else {
                    this.setState({
                        apiFail: true,
                    })
                }
            }
        });
    }

    redirectPage = () => {
        if (this.state.apiFail) {
            return <Redirect to='/404' />
        }
    }

    render() {
        let mainItemsArr =[];

        const { headline, mainItems, modules } = this.state;

        mainItems.forEach(function(item) {
            mainItemsArr.push(
                <div className="op-single__item">
                    <div className="op-single__item--left">
                        <a href={item.fields.url} target="_blank" rel="noopener noreferrer">
                            <img src={item.fields.image.fields.file.url} alt={item.fields.heading}/>
                        </a>
                    </div>

                    <div className="op-single__item--right">
                        <a href={item.fields.url} target="_blank" rel="noopener noreferrer">
                            <h3>{item.fields.heading}</h3>
                        </a>
                        <h4>{item.fields.subheading}</h4>
                        
                        <div dangerouslySetInnerHTML={{ __html: item.fields.body}}/>
                        
                        {item.fields.materialLink && (
                            <a href={item.fields.materialLink} target="_blank" className="hot-op__item--btn" rel="noopener noreferrer">Materials</a>
                        )}
                    </div>              
                </div>
            )
        });
 
        return (
            <div>
                {this.redirectPage()} 
                <OpportunityHeader
                    headline={headline} />

                <section className="hot-op">
                    <div className="container">
                        {mainItemsArr}
                    </div>
                </section>

                {modules && (
                    <OpportunityModules
                        showSubheadline={true}
                        modules={modules}/>
                )}
                

            </div>
          );
    }
}

export default OpportunitySingle;
