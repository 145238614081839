import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

import AdsModule from './adsolutions/AdsModule';

let singleContent;

var contentful = require('contentful');

var client = contentful.createClient({
  space: 'purcre4qggd1',
  // environment: 'staging',
  accessToken: 'vdIxkgnr7AFvqwzg7XFaqNaI9K4LmXOwzuvAquQOxok'
});

class App extends Component {
  constructor(props) {
      super(props);

      this.state = {
        redirectTo404: false,
        headline: '',
        introCopy: '',
        specialCssClass: '',
      };
  }

  componentDidMount() {
    singleContent = window.location.pathname;
    singleContent = singleContent.substring(singleContent.lastIndexOf('/')+1);

    console.log('URL', singleContent);

    client.getEntries({
      'content_type': 'standardPage',
      'fields.slug': singleContent,
      'include': 10
    }).catch(error => {
      console.log(error);
    }).then(data => {
      if (data) {
        // IF API CALL RETURNS 0 INSTEAD OF A JS ERROR 
        // WE SHOULD THROW A 404
        if (data.total !== 0) {
          // Set page title
          document.title = data.items[0].fields.title + ' | Warner Bros. Discovery';

          if (data.items[0].fields.slug === 'cnn-experience') {
            // ONE OFF FIX FOR URGENT REQUEST.
            // WM needed the images on mobile to be flipped for this one page with a fast turn around.
            // Special css-class '.mobile-swap' does this.  
            this.setState({
              specialCssClass: 'mobile-swap'
            });
          }

          // Top-level Page Tracking
          ReactGA.set({ page: '/' + singleContent }); // Update the user's current page
          ReactGA.pageview('/' + singleContent);

          this.setState({
            headline: data.items[0].fields.heading,
            introCopy: data.items[0].fields.intro,
            modules: data.items[0].fields.modules,
          });
        } else {
          this.setState({
            redirectTo404: true,
          })
        }
      }
    });
  }

  redirectPage = () => {
    if (this.state.redirectTo404) {
      return <Redirect to='/404' />
    }
  }

  render() {
    const { headline, introCopy, modules, specialCssClass } = this.state;

    return (
      <div>
        {this.redirectPage()}
        <section className="section__intro no-bottom-padding">
            <div className="container">
                <h1 className="section__intro--headline">{headline || ''}</h1>
                <div className="section__intro--body" dangerouslySetInnerHTML={{ __html: introCopy || '' }}/>
            </div>
        </section>

        {modules && (
          <AdsModule modules={modules} specialcss={specialCssClass}/>
        )}
      </div>
    );
  }
}

export default App;