import React, { Component } from 'react';

class ModuleText extends Component {
	render() {
		let positionClass  = '',
		    widthClass     = '';

		const { bodyCopy, position, width, showHorizontalSeparator } = this.props;

		if (width === 'Two-thirds Width (67%)') {
			widthClass = 'width-two-third';
		} else if (width === 'Half Width (50%)') {
			widthClass = 'width-half';
		}

		if (position === 'Middle') {
			positionClass = 'position-middle';
		} else if (position === 'Right') {
			positionClass = 'position-right';
		}

		return (
			<div className={`mod__text mod-styles ${widthClass} ${positionClass} ${showHorizontalSeparator ? 'line' : ''}`} 
				dangerouslySetInnerHTML={{ __html: bodyCopy}}/>
		  );
	}
}

export default ModuleText;