import React, { Component } from 'react';

class ModuleAdPlatform extends Component {
    render() {
        let platformArr = [],
            sectionsArr = [];

        const { item } = this.props;
        
        // Platform List
        item.fields.platforms.map((subitem, index) => (
            (subitem === "Mobile Web" && (
                platformArr.push(
                    <div className="adsol-icon">
                        <span className="icon">
                            <img src="/assets/images/adplatforms/icon-mobile-web.svg" alt="Mobile Web"/>
                        </span>
                        <span className="label">Mobile Web</span>
                    </div>
                )
            ) || subitem === "Mobile App" && (
                platformArr.push(
                    <div className="adsol-icon">
                        <span className="icon">
                            <img src="/assets/images/adplatforms/icon-mobile-app.svg" alt="Mobile App"/>
                        </span>
                        <span className="label">Mobile App</span>
                    </div>
                )
            ) || subitem === "Tablet Web" && (
                platformArr.push(
                    <div className="adsol-icon tablet">
                        <span className="icon">
                            <img src="/assets/images/adplatforms/icon-tablet.svg" alt="Tablet Web"/>
                        </span>
                        <span className="label">Tablet Web</span>
                    </div>
                )
            ) || subitem === "Desktop" && (
                platformArr.push(
                    <div className="adsol-icon lg">
                        <span className="icon">
                            <img src="/assets/images/adplatforms/icon-desktop.svg" alt="Desktop"/>
                        </span>
                        <span className="label">Desktop</span>
                    </div>
                )
            ))
        ));

        // Section List
        item.fields.sections.map((subitem, index) => (
            (subitem === "Homepage" && (
                sectionsArr.push(
                    <div className="adsol-icon homepage">
                        <span className="icon">
                            <img src="/assets/images/adplatforms/icon-homepage.svg" alt="Homepage"/>
                        </span>
                        <span className="label">Homepage</span>
                    </div>
                )
            ) || subitem === "Section Fronts" && (
                sectionsArr.push(
                    <div className="adsol-icon lg">
                        <span className="icon">
                            <img src="/assets/images/adplatforms/icon-section.svg" alt="Section Fronts"/>
                        </span>
                        <span className="label">Section Fronts</span>
                    </div>
                )
            ) || subitem === "Article Pages" && (
                sectionsArr.push(
                    <div className="adsol-icon lg">
                        <span className="icon">
                            <img src="/assets/images/adplatforms/icon-article-page.svg" alt="Article Pages"/>
                        </span>
                        <span className="label">Article Pages</span>
                    </div>
                )
            ) || subitem === "Mobile Article Pages" && (
                sectionsArr.push(
                    <div className="adsol-icon mobile-article">
                        <span className="icon">
                            <img src="/assets/images/adplatforms/icon-article-mobile.svg" alt="Mobile Article Pages"/>
                        </span>
                        <span className="label">Mobile Article<br/> Pages</span>
                    </div>
                )
            ))
        ))

        return (
            <div className="mod__avail-platforms mod-styles ">
                <div className="adsol-icon__wrapper">
                    <h6>Available Platforms</h6>
                    <div className="adsol-icon__flex">
                        {platformArr}
                    </div>
                </div>
                <div className="adsol-icon__wrapper">
                    <h6>Available Sections</h6>
                    <div className="adsol-icon__flex">
                        {sectionsArr}
                    </div>
                </div>
            </div>
          );
    }
}

export default ModuleAdPlatform;
