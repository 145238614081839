import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import ReactGA from 'react-ga';

import AdsHeader from './AdsHeader';
import AdsModule from './AdsModule';
import AdsNav from './AdsNav';
import AdsSubNav from './AdsSubNav';
import AdsRelatedMedia from './AdsRelatedMedia';

import ModuleFastFacts from '../modules/ModuleFastFacts';

import { Waypoint } from 'react-waypoint';

var contentful = require('contentful');

var client = contentful.createClient({
  space: 'purcre4qggd1',
  // environment: 'staging',
  accessToken: 'vdIxkgnr7AFvqwzg7XFaqNaI9K4LmXOwzuvAquQOxok'
});

class AdsSingle extends Component {
    constructor(props) {
        super(props);

        this.state = {
            apiFail: false,
            isParentPage: false,
            headline: '',
            introCopy: '',
            modules: [],
            navItems: [],
            subCategories: [],
            hasSubCats: false,
            fastFacts: [],
            caseStudies: [],
            hotOps: [],
            isSticky: 'not-sticky',
        };

        this.handleWaypointEnter = this.handleWaypointEnter.bind(this);
        this.handleWaypointLeave = this.handleWaypointLeave.bind(this);
    }

    handleWaypointEnter(e) {
        if (e.currentPosition === 'inside') {
            this.setState({
                isSticky: 'not-sticky',
            });
        }
    }

    handleWaypointLeave(e) {
        if (e.currentPosition === 'above') {
            this.setState({
                isSticky: 'sticky',
            });
        }
    }

    componentDidMount() {
        let pathArray = [];
        let queryString = '',
            parentCategory = '';

        // Grab the pathname and splice it up figure out if we are querying a top level page or a subpage
        pathArray = window.location.pathname.split('/');

        for (let i = 2; i < pathArray.length; i++) {
            queryString += pathArray[i];
            // Put a slash into the item if there is a subpage
            if ( i === 2 && pathArray.length > 3) {
                parentCategory += pathArray[i];
                queryString += "/";
            }
        }

        // If the Ad Solution has a parent category, we need to query the parent to get the subnav.
        if (parentCategory !== '') {
            client.getEntries({
                'content_type': 'adSolution',
                'fields.slug': parentCategory,
                'include': 10
            }).then(data => {
                if (data) {
                    // console.log('Parent Data', data);
                    // IF API CALL RETURNS 0 INSTEAD OF A JS ERROR 
                    // WE SHOULD THROW A 404
                    if (data.total !== 0) {
                        if (data.items[0].fields.subcategories) {
                            this.setState({
                                subCategories: data.items[0].fields.subcategories,
                                hasSubCats: true,
                            });
                        }
                        
                    }
                }
            });
        }

        client.getEntries({
            'content_type': 'adSolution',
            'fields.slug': queryString,
            'include': 10
        }).then(data => {
            if (data) {
                // console.log('Single', data);
                // IF API CALL RETURNS 0 INSTEAD OF A JS ERROR 
                // WE SHOULD THROW A 404
                if (data.total !== 0) {
                    var pathData = data.items[0].fields;

                    document.title = pathData.title + ' | Ad Solutions | Warner Bros. Discovery';

                    // Add Tracking
                    // Top-level Page Tracking
                    ReactGA.set({ page: '/ad-solutions/' + queryString }); // Update the user's current page
                    ReactGA.pageview('/ad-solutions/' + queryString);
                    
                    if (data.items[0].fields.subcategories) {
                        // If Page has subcats it is a parent page (template type 2), 
                        // so we can show the subnav for the categories for a user to navigate to.
                        this.setState({
                            isParentPage: true,
                            hasSubCats: true,
                            subCategories: pathData.subcategories,
                        });
                    } else {
                        console.log('Ad Single', pathData);

                        this.setState({
                            headline: pathData.heading,
                            introCopy: pathData.intro,
                            modules: pathData.modules,
                            fastFacts: pathData.fastFacts,
                            caseStudies: pathData.caseStudies,
                            hotOpportunities: pathData.hotOpportunities,
                        });
                    }
                } else {
                    this.setState({
                        apiFail: true,
                    })
                }
            }
        });

    }

    redirectPage = () => {
        if (this.state.apiFail) {
            return <Redirect to='/404' />
        }
    }

    render() {
        const { headline, 
                introCopy, 
                modules, 
                hasSubCats, 
                subCategories, 
                isParentPage, 
                fastFacts, 
                caseStudies, 
                hotOpportunities,
                isSticky } = this.state;

        return (
            <div>
                {this.redirectPage()} 
                <AdsHeader
                    size={'no-bottom-padding'}
                    headline={headline}
                    introCopy={introCopy}/>

                {hasSubCats && (
                    <div className={`adsol-subnav__extra ${isSticky}`}>
                        <Waypoint
                          onEnter={this.handleWaypointEnter}
                          onLeave={this.handleWaypointLeave}
                          topOffset="60"/>

                        <div className={`adsol-subnav__wrapper ${this.state.isSticky}`}>
                            <AdsSubNav items={subCategories}/>
                        </div>
                    </div>
                )}

                <div className={`adsol-single ${hasSubCats === true ? 'grey' : 'white'}`}>
                    <div className={`adsol-single__wrapper ${hasSubCats === true ? 'grey' : 'white'}`}>
                        {/* Parent Pages wont have submodules */}
                        {isParentPage === false && (
                            <AdsModule modules={modules}/>
                        )}
                    </div>
                
                </div>

                {fastFacts && (
                    <div className="adsol-single fast-facts">
                        <ModuleFastFacts 
                            background={hasSubCats === true ? 'grey' : 'white'}
                            item={fastFacts}/>
                    </div>
                )}

                <div className={`adsol-single ${hasSubCats === true ? 'grey' : 'white'}`}>
                    <div className={`adsol-single__wrapper ${hasSubCats === true ? 'grey' : ''}`}>

                        {caseStudies && (
                            <AdsRelatedMedia
                                type="Case Studies"
                                assets={caseStudies}/>
                        )}

                        {hotOpportunities && (
                            <AdsRelatedMedia
                                type="Hot Opportunities"
                                assets={hotOpportunities}/>
                        )}
                        
                        <div className="container">
                            <hr className="margin-top-bottom"/>
                        </div>
                        
                        <AdsNav 
                            showDescription={false}/>
                    </div>
                </div>
            </div>
          );
    }
}

export default AdsSingle;
