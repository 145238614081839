import React, { Component } from 'react';

class ModuleFastFacts extends Component {

    render() {
        const { item, background, linebreak } = this.props;

        return (
            <div className="brand-single--no-padding">
                {item.fields && (
                    <div>
                        <div className={`fast-facts__section--headline ${background}`}>
                            <h2><span>{item.fields.heading}</span></h2>
                        </div>
                        <div className={`brand-single__wrapper ${background} ${linebreak ? 'line' : ''}`}>
                            {item.fields.stats && (
                                <div className="container">
                                    {item.fields.stats[0].heading && (
                                        <p className="fast-facts__paragraph">{item.fields.stats[0].heading}</p>
                                    )}

                                    {item.fields.stats[0].reach_highlights.length >= 1 && (
                                        <div>
                                            {item.fields.stats[0].show_reach_highlights_subhead && (
                                                <h3 className="fast-facts__headline">
                                                    {item.fields.reachHighlightsCustomSubhead && (
                                                        <span>{item.fields.reachHighlightsCustomSubhead}</span>
                                                    )}
                                                    {!item.fields.reachHighlightsCustomSubhead && (
                                                        <span>Reach Highlights</span>
                                                    )}
                                                </h3>
                                            )}
                                            <div className="fast-facts__section">
                                                {item.fields.stats[0].reach_highlights.map((subitem, index) => (
                                                    <div className="fast-facts__item" key={subitem.label + index}>
                                                        <p className="fast-facts__item--top">{subitem.value}</p>
                                                        <p className="fast-facts__item--bottom">{subitem.label}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {item.fields.stats[0].audience_overview.length >= 1 && (
                                        <div>
                                            {item.fields.stats[0].show_audience_overview_subhead && (
                                                <h3 className="fast-facts__headline">
                                                    {item.fields.audienceOverviewCustomSubhead && (
                                                        <span>{item.fields.audienceOverviewCustomSubhead}</span>
                                                    )}
                                                    {!item.fields.audienceOverviewCustomSubhead && (
                                                        <span>Audience Oveview</span>
                                                    )}
                                                </h3>
                                            )}
                                            <div className="fast-facts__section">
                                                {item.fields.stats[0].audience_overview.map((subitem, index) => (
                                                    <div className="fast-facts__item" key={subitem.label + index}>
                                                        <p className="fast-facts__item--top">{subitem.value}</p>
                                                        <p className="fast-facts__item--bottom">{subitem.label}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {item.fields.stats[0].audience_highlights.length >= 1 && (
                                        <div>
                                            {item.fields.stats[0].show_audience_highlights_subhead && (
                                                <h3 className="fast-facts__headline">
                                                    {item.fields.audienceHighlightsCustomSubhead && (
                                                        <span>{item.fields.audienceHighlightsCustomSubhead}</span>
                                                    )}
                                                    {!item.fields.audienceHighlightsCustomSubhead && (
                                                        <span>Audience Highlights</span>
                                                    )}
                                                </h3>
                                            )}
                                            <div className="fast-facts__section--single">
                                                {item.fields.stats[0].audience_highlights.map((subitem, index) => (
                                                    <div className="fast-facts__item" key={subitem.label + index}>
                                                        <p className="fast-facts__item--top">{subitem.value}</p>
                                                        <p className="fast-facts__item--bottom">{subitem.label}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    <div className="fast-facts__source" dangerouslySetInnerHTML={{ __html: item.fields.sourceCopy }}/>

                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default ModuleFastFacts;
