import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';

class Nav extends Component {

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleSearch = this.handleSearch.bind(this)
  }

  handleClick(label) {
    this.props.closeHamburgerNav();
    // console.log(label)
    ReactGA.event({
      category: 'Navigation',
      action: 'Click',
      label: label
    });
  }

  handleSearch(label) {
    this.props.toggleSearchModal();
    // console.log(label)
    ReactGA.event({
      category: 'Modal',
      action: 'Click',
      label: label
    });
  }

  render() {
    const navbarState = this.props.navState ? 'opened' : '',
          searchState = this.props.searchModalState ? 'hide' : '';

    return (
      <div className="nav">
        <div className="nav__logo">
          <Link to='/' onClick={() => {this.handleClick('Nav - Logo')}}>
            <img src="/assets/images/WBD_HorizontalLogo_AllBlack.png" alt="Warner Bros. Discovery Logo"/>
          </Link>
        </div>

        <div className={`nav__hamburger ${navbarState}`} onClick={this.props.toggleHamburgerNav}>
          <span></span>
        </div>

        <nav className={`nav__bar ${navbarState}`}>
          <ul>
            <li>
              <NavLink to='/brands' activeClassName='active' className="nav__link" onClick={() => {this.handleClick('Nav - Brands')}}>
                Our Brands
              </NavLink> 
            </li>
            <li>
              <NavLink to='/case-studies' activeClassName='active' className="nav__link" onClick={() => {this.handleClick('Nav - Case Studies')}}>
                Case Studies
              </NavLink> 
            </li>
            <li>
              <NavLink to='/courageous' activeClassName='active--yellow' className="nav__link" onClick={() => {this.handleClick('Nav - Brand Studio')}}>
                Brand Studio
              </NavLink>
            </li>
            <li>
              <NavLink to='/ad-solutions' activeClassName='active' className="nav__link" onClick={() => {this.handleClick('Nav - Ad Solutions')}}>
                Ad Solutions
              </NavLink>
            </li>
            <li>
              <NavLink to='/hot-opportunities' activeClassName='active' className="nav__link" onClick={() => {this.handleClick('Nav - Opportunities')}}>
                Opportunities
              </NavLink>
            </li>
          
            <li>
              <span className={`nav__link--search ${searchState}`} onClick={() => {this.handleSearch('Nav - Open Search')}}>
                <img src="/assets/images/icon-search.svg" alt="Icon - Search"/>
              </span>
            </li>
          </ul>
          
        </nav>


      </div>


    );
  }
}

export default Nav;