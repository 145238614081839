import React, { Component } from 'react';
import ReactGA from 'react-ga';

import VimeoModal from './utility/VimeoModal';
import Vimeo from '@u-wave/react-vimeo';

class Homepage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            vimeoID: 131822365,
            imageModalState: false,
        };

        this.openModal = this.openModal.bind(this);
    }

    openModal(e) {
        let vimeoVal = e.target.dataset.vimeourl;

        console.log(vimeoVal);

        e.preventDefault();

        this.setState({
            isOpen: true,
            vimeoID: vimeoVal,
        });

        console.log(this.state);
    }

    componentDidMount() {
        document.title = 'CNN Candid Conversations | Warner Bros. Discovery';

        // Top-level Page Tracking
        ReactGA.set({ page: '/candid-conversations/'}); // Update the user's current page
        ReactGA.pageview('/candid-conversations/');
    }

    render() {
    
        return (
            <main className="p-candid">
                <header className="p-candid__header">
                    <div className="container">
                        <div className="p-candid__headline--flex">
                            <div className="p-candid__headline--left">
                                <img src="/assets/images/logo-cnn.svg" alt="Logo - CNN"/>
                            </div>
                            <div className="p-candid__headline--right">
                                <h1 class="p-candid--headline white">Essential. Every Day.  <br/> And More Than You Think.</h1>
                            </div>
                        </div>

                        <div className="p-candid__header--intro">
                            <p class="p-candid--headline-text white">Every day, everywhere, consumers around the world turn to CNN – for the moments that matter most, and for all the moments in between. CNN is a daily ritual and essential part of peoples’ lives. It keeps consumers informed, educated and engaged, creating a halo effect for brand partners, too. But CNN is more than you think. From our immersive digital lifestyle brands to our Original Series & Films to our addictive podcasts and more, CNN offers brands incredible and meaningful ways to connect.</p>
                        </div>

                        <div className="p-candid__vid">
                            <div className="p-candid__vid--left">
                                {/*
                                <div className="p-candid__vid--thumbnail" data-vimeourl={533153575} onClick={this.openModal}>
                                    <img src="/assets/images/candid/candid-vid-1.jpg" alt="CNN Candid Conversations First Video Thumbnail" data-vimeourl={533153575} />
                                </div> 
                                */ }
                                <Vimeo
                                    video={533153575}
                                    responsive={true}
                                    showTitle={false}
                                    showPortrait={false}
                                    showByline={false}
                                    color='c00000'/>                               
                            </div>
                            <div className="p-candid__vid--right">
                                <h2>Connect to<br/> What Matters</h2>
                                <p>Trust is earned over time. For more than 40 years, CNN has been there for audiences all around the world; delivering facts, stories and experiences to help us better understand this world and each other. Take a look. </p>
                            </div>
                        </div>
                    </div>
                </header>

                <section className="p-candid__section--images">
                    <div className="container">
                        <h2 className="p-candid--headline">First Look Opportunities</h2>
                        <p className="p-candid--headline-text">We’re excited to share the following opportunities for the 2021-2022 upfront season. These robust, 360-degree offerings lean into themes that are core to our mission: journalism, humanity, community, purpose and exploration.</p>

                        <div className="p-candid__image">
                            <div className="p-candid__image--left">
                                <img src="/assets/images/candid/candid-img-1.jpg" alt="CNN Candid Conversations #1"/>
                            </div>
                            <div className="p-candid__image--right">
                                <h3 className="p-candid__image--headline">AC Everywhere</h3>
                                <p className="p-candid__image--copy">Consumers love Anderson Cooper. That’s why in 2021, for his 20th anniversary, CNN will leverage his star power across TV, digital, social and audio (coming soon) to connect with viewers on a deeper, more personal level. AC Everywhere will offer viewers another side of Anderson. He’ll discuss with guests topics like: health and wellness, mindfulness, parenting, equality and more. Brands have the opportunity to align through meaningful and unique integrations, custom events and branded content across all platforms -- all aimed to encourage viewers to interact and engage with Anderson and his esteemed guests.</p>
                            </div>
                        </div>

                        <div className="p-candid__image">
                            <div className="p-candid__image--left">
                                <img src="/assets/images/candid/candid-img-2.jpg" alt="CNN Candid Conversations #2"/>
                            </div>
                            <div className="p-candid__image--right">
                                <h3 className="p-candid__image--headline">Celebrating More CNN Heroes</h3>
                                <p className="p-candid__image--copy">71% of CNN multiplatform visitors agree, “people like me have the power to help influence important changes that shape the future.” CNN Heroes, now in its 15th year celebrates incredible individuals doing just that. To inspire audiences even more, we're expanding the beloved CNN Heroes franchise with more ways for brands to connect to these powerful stories. We’ll identify new heroes aligned to different topics and genres and increase programming on digital, social and on-air. Brands will have opportunities to integrate in impactful ways, including a donation match, grants, in-store activations for voting or by supporting non-profits with actionable ways to raise money.</p>
                            </div>
                        </div>

                        <div className="p-candid__image">
                            <div className="p-candid__image--left">
                                <img src="/assets/images/candid/candid-img-3.jpg" alt="CNN Candid Conversations #3"/>
                            </div>
                            <div className="p-candid__image--right">
                                <h3 className="p-candid__image--headline">Tasting the World</h3>
                                <p className="p-candid__image--copy">CNN is leaning into food, travel and culture in a big way in 2021-2022, combining the audience fervor around <i>Stanley Tucci: Searching for Italy</i> (our highest ranking Original Series  among total viewers of all time) with the CNN Film <i>Julia</i> and highly anticipated Anthony Bourdain documentary to bring advertising partners the Tasting the World, package. The exploration of food and culture will continue through CNN Digital, with a robust collection of content that taps into travel and lifestyle through featured recipes, behind the scenes bonus content and more. Along the journey, brands will have unique opportunities to execute custom integrations, including sponsored tasting events during key pulse points.</p>
                            </div>
                        </div>

                        <div className="p-candid__image">
                            <div className="p-candid__image--left">
                                <img src="/assets/images/candid/candid-img-4.jpg" alt="CNN Candid Conversations #4"/>
                            </div>
                            <div className="p-candid__image--right">
                                <h3 className="p-candid__image--headline">Project Planet Gets Bigger</h3>
                                <p className="p-candid__image--copy">CNN has been committed to climate coverage for well over a decade and recently expanded this effort with dedicated force. Our audience is equally leaned in on this topic, with 44% saying they are more likely to believe that “climate change is the biggest threat to civilization.” In 2022, we’ll be investing even more into this important story with an expansion of our Project Planet platform and dedicated soon-to-be-announced TV special. We’ll work with brands to tell meaningful brand stories and create ways to make change by activating consumers to raise funds, volunteer and more. Join us in this cultural conversation that matters deeply to CNN audiences across the globe.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="p-candid__section--vid-2">
                    <div className="container">
                        <div className="p-candid__vid swap">
                            <div className="p-candid__vid--left">
                                {/*
                                <div className="p-candid__vid--thumbnail" data-vimeourl={533798908} onClick={this.openModal}>
                                    <img src="/assets/images/candid/candid-vid-2.jpg" alt="CNN Candid Conversations Second Video Thumbnail" data-vimeourl={533798908} />
                                </div>
                                */ }
                                <Vimeo
                                    video={533798908}
                                    responsive={true}
                                    showTitle={false}
                                    showPortrait={false}
                                    showByline={false}
                                    color='c00000'/>                                    
                            </div>
                            <div className="p-candid__vid--right">
                                <h2>CNN Original Series & Films</h2>
                                <p>From Emmy Award-winning films, such as <i>Apollo 11</i>, to brand-new, and wildly popular, series like <i>Stanley Tucci: Searching for Italy</i> – CNN Originals & Films take viewers on a journey to meet new people and cultures – and to lean into topics that truly matter. Take a look at what’s coming in 2021-2022.</p>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="p-candid__section--fast-facts">
                    <div className="fast-facts__section--headline brand-archive">
                        <h2><span>Fast Facts</span></h2>
                    </div>
                    <div className="container">
                        <div>
                            <div className="fast-facts__section">
                                <div className="fast-facts__section--single">
                                    <p className="fast-facts__item--bottom">CNN is the most trusted, most diverse and most consumed news brand in the world:</p>
                                </div>
                                <div className="fast-facts__section--single">
                                    <p className="fast-facts__item--top">#1 GLOBAL NEWS BRAND</p>
                                    <p className="fast-facts__item--bottom">As the most trusted news brand, CNN reaches 317 million worldwide and 68% of the U.S. population online</p>
                                </div>

                                <div className="fast-facts__section--single">
                                    <p className="fast-facts__item--top">DIVERSE AUDIENCES</p>
                                    <p className="fast-facts__item--bottom">CNN is the most consumed network online and on-air with Black and Hispanic audiences</p>
                                </div>

                                <div className="fast-facts__section--single">
                                    <p className="fast-facts__item--top">GROWING IN NEWS AREAS</p>
                                    <p className="fast-facts__item--bottom">In 2020, Lifestyle verticals all grew by double digits; CNN Audio grew 17x and CNN Originals & Films drove 2M new viewers per quarter in 2020</p>
                                </div>

                                <div className="fast-facts__section--single">
                                    <p className="fast-facts__item--top">HIGHLY EFFECTIVE</p>
                                    <p className="fast-facts__item--bottom">Brands advertising on CNN see 5-6% point lifts in favorability, purchase intent, and ad awareness and a 12% point lift in unaided brand awareness</p>
                                </div>

                                <div className="fast-facts__section--single">
                                    <p className="fast-facts__item--top">ENGAGED AUDIENCES</p>
                                    <p className="fast-facts__item--bottom">CNN outpaces every other competitor in terms of time spent, with +82% growth this past year across platforms</p>
                                </div>
                            </div>
                        </div>
                        <div className="fast-facts__source">
                            <p>SOURCE: YouGov online survey among 20 key countries and 30 brands, Dec 17th-Jan 4th, 2021, P18+.* Next, considering all the various sources of news and information about politics, business, and finance below…Which, if any, do you trust? Please select all that apply. **Survey was weighted based on best available population data in each country (total pop for most countries, national representative of urban areas in some countries (India, Brazil, Mexico, Turkey & Saudi Arabia) or based on internet population (China & Indonesia). Global Reach Comscore XMedia Total view Audience Analysis Report. Live+7. Period: Jan-Dec 2020, average monthly. Television numbers are based on broadcast month, Digital and mobile are based on calendar month. International TV data based on the digital domestic to international reach. Digital = ComScore Multiplatform Media Metrix, 2020 vs. 2019 avg monthly, Total Audience, CNN.COM. TV, Nielsen via Npower, Jan 2021, Live +7. Digital Audiences = Comscore Multiplatform Media Metrix, ComScore Multiplatform Media Metrix, 2020 vs. 2019 avg monthly, CNN.COM. Nielsen via Npower. New viewers claim based on 2020 reach, 1 min qualifier comparing New CNN viewers that watched originals in each quarter of 2020 with CNN TD reach the prior quarter. Tucci and Lincoln claims based on 2/14-3/14/21, Live +7, Sunday 9-11p. comScore XMedia Total View Audience Analysis Report. Live + 7 playback period used for TV. January 2021 vs. January 2020. Domestic (U.S.) audience. CNN is “CNN.com”  for digital/mobile, “CNN” for TV. Time spent and growth based on Total Minutes. YouGov Profiles USA, January 2021. CNN Multiplatform Visitors = TV networks regularly watched is CNN OR News apps currently downloaded is CNN OR TV network websites visited last 30 days is CNN.com. Index Z Score above 1.96. vs. Nationally Representative Audience."
                            </p>
                        </div>
                    </div>
                </section>

                <section className="p-candid__cta">
                    <div className="container">
                        <h3 className="p-candid__cta--headline"><a href="/hot-opportunities">Click here</a> to see more opportunities or contact your sales rep for more information</h3>
                        <a className="p-candid__btn" href="mailto:newsmarketing@warnermedia.com" target="_blank" rel="noopener noreferrer" >Contact Us</a>
                    </div>
                </section>

                <VimeoModal 
                    channel='vimeo' 
                    isOpen={this.state.isOpen} 
                    videoId={this.state.vimeoID} 
                    onClose={() => this.setState({isOpen: false})}/>
            </main>
          );
    }
}

export default Homepage;
