import React, { Component } from 'react';
import axios from 'axios';

import ReactGA from 'react-ga';

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      title: '',
      company: '',
      email: '',
      body: '',
      firstNameError: false,
      lastNameError: false,
      titleError: false,
      companyError: false,
      bodyError: false,

      emailSent: false,
      passwordValid: false,
      formValid: false,
      formError: false,
      successfulSubmit: false,
      emailValid: false,
      errorMsg: '',
    };

    this.handleUserInput = this.handleUserInput.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({[name]: value}); 

    if (value === '') {
      this.setState({
        formError: true,
        [name+'Error']:  true,
        errorMsg: 'Please enter the required fields',
      })
    } else {
      this.setState({
        formError: false,
        [name+'Error']:  false,
        errorMsg: '',
      })
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.firstName === '') {
      this.setState({
        formError: true,
        firstNameError: true,
        errorMsg: 'Please enter the required fields',
      });
    } else {
      this.setState({
        formError: false,
        firstNameError: false,
        errorMsg: '',
      });
    }

    if (this.state.lastName === '') {
      this.setState({
        formError: true,
        lastNameError: true,
        errorMsg: 'Please enter the required fields',
      });
    } else {
      this.setState({
        formError: false,
        lastNameError: false,
        errorMsg: '',
      });
    }

    if (this.state.title === '') {
      this.setState({
        formError: true,
        titleError: true,
        errorMsg: 'Please enter the required fields',
      });
    } else {
      this.setState({
        formError: false,
        titleError: false,
        errorMsg: '',
      });
    }

    if (this.state.company === '') {
      this.setState({
        formError: true,
        companyError: true,
        errorMsg: 'Please enter the required fields',
      });
    } else {
      this.setState({
        formError: false,
        companyError: false,
        errorMsg: '',
      });
    }

    if (this.state.body === '') {
      this.setState({
        formError: true,
        bodyError: true,
        errorMsg: 'Please enter the required fields',
      });
    } else {
      this.setState({
        formError: false,
        bodyError: false,
        errorMsg: '',
      });
    }


    if (this.state.email === '') {
      this.setState({
        formError: true,
        emailError: true,
        errorMsg: 'Please enter the required fields',
      }); 
    } else {
      let emailValid = this.state.email;
      emailValid = this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

      // console.log('Value', this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i));
      
      if (!emailValid) {
        this.setState({
          formError: true,
          emailError: true,
          errorMsg: this.state.email + " is not a valid email address",
        });
      } else {
        this.setState({
          emailValid: true,
          emailError: false,
          formError: false
        });

        if (!this.state.formError) {
          let formArr = {
            "subject": "New Contact Form Submission", 
            "reply_to": "connor.hubeny@digitalcitizen.nyc",
            "body": "Name: " + this.state.firstName + " " + this.state.lastName + ", Title: " + this.state.title + ", Company: " + this.state.company + ", Email: " + this.state.email + ", Message: " + this.state.body,
          }

          // POST
          axios.post('https://oujwyjo1bj.execute-api.us-east-1.amazonaws.com/', formArr)
          .catch(error => {
            console.log('Form Error', error);
          }).then(res => {
            console.log(res);
            if (res) {
              if (res.status === 200) {
                // Message was succesfully sent
                this.setState({
                  emailSent: true,
                });

                ReactGA.event({ 
                  category: 'Contact', 
                  action: 'Submit', 
                  label: 'Contact Form Submitted' 
                });
                
              }
            } else {
              this.setState({
                formError: true,
                errorMsg: "Something went wrong, please try again."
              });
            }
          })
        }
      }
    }
  }

  render() {
    // const copyrightYear = Date.getYear();
    const { formError, errorMsg, emailSent, firstNameError, lastNameError, titleError, companyError, bodyError, emailError } = this.state; 

    return (
      <div>
        {emailSent && (
          <h3 className="contact__success-msg">
            Thank you. <br/>
            We’ll be in touch soon.
          </h3>
        )}

        {!emailSent && (
          <form onSubmit={this.handleSubmit}>
            <input name="firstName" className={`contact__input--double ${firstNameError ? 'error' : ''}`} type="text" placeholder="First Name*" value={this.state.firstName} onChange={this.handleUserInput}/>
            <input name="lastName" className={`contact__input--double ${lastNameError ? 'error' : ''}`} type="text" placeholder="Last Name*" value={this.state.lastName} onChange={this.handleUserInput}/>
            <input name="title" className={`contact__input--double ${titleError ? 'error' : ''}`} type="text" placeholder="Title*" value={this.state.title} onChange={this.handleUserInput}/>
            <input name="company"className={`contact__input--double ${companyError ? 'error' : ''}`} type="text" placeholder="Company*" value={this.state.company} onChange={this.handleUserInput}/>
            <input name="email" className={`contact__input ${emailError ? 'error' : ''}`} type="text" placeholder="Email*" value={this.state.email} onChange={this.handleUserInput}/>
            <textarea name="body" placeholder="What can we help you with?*" value={this.state.body} onChange={this.handleUserInput} className={`${bodyError ? 'error' : ''}`}/>
            {formError && (
              <p className="contact__error-msg">{errorMsg}</p>
            )}
            <input type="submit" value="Submit"/>
          </form>
        )}
      </div>
    );
  }
}

export default ContactForm;
