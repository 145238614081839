import React, { Component } from 'react';
import ReactGA from 'react-ga';

import ModuleMediaGallery from './modules/ModuleMediaGallery';

var contentful = require('contentful')

var client = contentful.createClient({
  space: 'purcre4qggd1',
  // environment: 'staging',
  accessToken: 'vdIxkgnr7AFvqwzg7XFaqNaI9K4LmXOwzuvAquQOxok'
});

// Order capital/lowercase letters together
function case_insensitive_comp(strA, strB) {
    return strA.toLowerCase().localeCompare(strB.toLowerCase());
}

class Homepage extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
	    	isOpen: false,
	    	vimeoID: '131822365',
	    	headline: 'Be Bold. Be Relevant.',
	    	subline: 'Be Courageous.',
	    	intro: '',
	    	bgImg: '',
	    	fgImg: '',
	    	highlights: [],
	    	featuredWork: [],
	    	capabilities: [],
	    	recentAwards: [],
	    	research: [],
	    	brandPartners: [],
	    	brandPartnersHeadline: ''
	    };

	    this.openModal = this.openModal.bind(this)
	}

	openModal (e) {
		let vimeoVal = e.target.dataset.vimeourl;
		e.preventDefault();

    	this.setState({
    		isOpen: true,
    		vimeoID: vimeoVal,
    	})
  	}

	componentDidMount() {
		document.title = 'Brand Studio | Warner Bros. Discovery';

		// Top-level Page Tracking
		ReactGA.set({ page: '/courageous/'}); // Update the user's current page
  	    ReactGA.pageview('/courageous/');

		client.getEntries({
			content_type: 'brandStudioPage',
	    	'fields.slug': 'courageous',
	    	'include': 10
	    	// order: 'sys.createdAt'
	    }).then(data => {
	    	if (data) {
	    		let pathData = data.items[0].fields;
	    		console.log(pathData);
	    		this.setState({
	    			headline: pathData.heading,
	    			subline: pathData.subheading,
	    			intro: pathData.intro,
	    			bgImg: pathData.backgroundImage.fields.file.url,
			    	fgImg: pathData.foregroundImage.fields.file.url,
			    	highlights: pathData.highlights,
			    	featuredWork: pathData.featuredWork,
			    	pullquote: pathData.pullquote,
			    	capabilities: pathData.capabilitiesList,
			    	recentAwards: pathData.recentAwards,
			    	research: pathData.research,
			    	libraryUrl: pathData.courageousLibraryUrl,
			    	brandPartners: pathData.brandPartnersList.sort(case_insensitive_comp),
			    	brandPartnersHeadline: pathData.brandPartnershipHeading,
	    		});
	    	}
	    });
	}

	render() {
		const { headline, subline, intro, fgImg, bgImg, highlights, capabilities, libraryUrl, brandPartners, pullquote, recentAwards, research, brandPartnersHeadline, featuredWork } = this.state;
		let bgImage = {};

		if (bgImg) {
			bgImage = {
                backgroundImage: `url(${bgImg})`,
            }
		}

		return (
		    <main className="brand-studio">
		    	<header className="brand-studio__header" style={bgImage}>
		    	<a className="brand-studio__header--btn" href="http://courageousstudios.com" target="_blank" rel="noopener noreferrer">Visit CourageousStudios.com</a>
			    	<div className="container">
				    	<div className="brand-studio__header--flex">
				    		<div className="brand-studio__header--left">
						    	<h1 className="brand-studio__headline">{headline}</h1>
						    	<h2 className="brand-studio__headline--yellow">{subline}</h2>
						    	<div dangerouslySetInnerHTML={{__html: intro}}/>

						    	{highlights && (
						    		<ul className="brand-studio__list">
							    		{highlights.map((item, index) => (
							    			<li key={index}><h5>{item}</h5></li>
							    		))}
							    	</ul>
						    	)}
						    </div>
						    <div className="brand-studio__header--right">
						    	<img src={fgImg} alt="Logo - Courageous"/>
						   	</div>
				    	</div>
			    	</div>
		    	</header>

		    	{featuredWork && (
		    		<div className="brand-studio__work">
		    			<div className="container">
		    				<ModuleMediaGallery item={featuredWork}/>
		    			</div>
		    			
	    				<div className="container">
			    			<div className="brand-studio__btn--wrapper">
				    			{libraryUrl && (
				    				<a href={libraryUrl}
				    				className="brand-studio__btn"
				    				target="_blank"
				    				rel="noopener noreferrer"
				    				onClick={() => {ReactGA.event({ category: 'Navigation', action: 'Click', label: 'Search Courageous Library'})}}>Search Courageous Library</a>
				    			)}
			    			</div>
		    			</div>
		   
		    			
		    		</div>
		    	)}

			    <section className="brand-studio__body">				    
				    <div className="container">
				    	<div className="courageous__col">
				    		<div className="courageous__col--left">
					    		{capabilities && (
					    			<div>
					    				<h3 className="courageous-work__headline">Capabilities</h3>
						    			<div className="courageous__capabilities">
						    				<ul>
							    				{capabilities.map((item, index) => (
							    					<li key={index}>{item}</li>
							    				))}
						    				</ul>

						    				{pullquote && (
						    					<h4 className="courageous__capabilities--quote">{pullquote}</h4>
						    				)}
						    			</div>
						    		</div>
					    		)}
					    	</div>
					    	<div className="courageous__col--right">
					    		{recentAwards && (
					    			<div>
					    				<h3 className="courageous-work__headline">Recent Awards</h3>
					    				<div className="courageous__research" dangerouslySetInnerHTML={{__html: recentAwards}}/>
						    		</div>
					    		)}

					    		{research && (
					    			<div>
					    				<h3 className="courageous-work__headline">Research</h3>
					    				<div className="courageous__research" dangerouslySetInnerHTML={{__html: research}}/>
					    			</div>
					    		)}
						    	
						    </div>
					    </div>
					    <hr/>
					    {brandPartners && (
			    			<div className="courageous__partners">
			    				<h3 className="courageous-work__headline">{brandPartnersHeadline}</h3>
			    				<ul>
		    						{brandPartners.map((item, index) => (
		    							<li key={index}>{item}</li>
		    						))}
			    				</ul>
			    			</div>
				    	)}
				    </div>
			    </section>
		    </main>
		  );
	}
}

export default Homepage;
