import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';

import Slider from "react-slick";

function SubnavNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SubnavPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}


class AdsSubNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeItem: 0,
        };
    }

    render() {
        const { items } = this.props;

        let pathName = window.location.pathname;

        let subNavCount = 0,
            subnavActive, 
            subnavFinal,
            subNav = [];

        let carouselSettings,
            navClass = '';

        items.forEach(function(item) {
            // console.log(item);
            if ('/ad-solutions/' + item.fields.slug === pathName) {
                subnavActive = subNavCount;
                if (subnavActive === 0) {
                    subnavFinal = 0;
                } else {
                    subnavFinal = subnavActive;
                }
            }

            subNavCount++;

            subNav.push(
                <NavLink className="adsol-subnav__item"
                    activeClassName='active'
                    to={`/ad-solutions/${item.fields.slug}`}
                    key={`/ad-solutions/${item.fields.slug}`}
                    onClick={() => {ReactGA.event({ category: 'Ad Solutions', action: 'Click', label: 'Subnav - ' + item.fields.title})}}>
                    <img className="active" src={item.fields.iconActiveState.fields.file.url} alt={`Subnav - ${item.fields.title}`}/>
                    <img className="inactive" src={item.fields.icon.fields.file.url} alt={`Subnav - ${item.fields.title}`}/>
                </NavLink>
            );
        });

        // If carousel has 4 slides
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: subnavFinal,
            nextArrow: <SubnavNextArrow className="ads-subnav__next" />,
            prevArrow: <SubnavPrevArrow className="ads-subnav__prev" />,
            responsive: [{
              breakpoint: 1100,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: false,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false,
              }
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false,
              }
            }]
        };

        // if Carousel has 3 slides
        var settings2 = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: subnavFinal,
            nextArrow: <SubnavNextArrow className="adsol-subnav__next" />,
            prevArrow: <SubnavPrevArrow className="adsol-subnav__prev" />,
            responsive: [{
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false,
              }
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
              }
            }]
        };

        // If Carousel has 2 slides
        var settings3 = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: subnavFinal,
            nextArrow: <SubnavNextArrow className="adsol-subnav__next" />,
            prevArrow: <SubnavPrevArrow className="adsol-subnav__prev" />,
            responsive: [{
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
              }
            }]
        };

        // If Carousel has 1 slides
        var settings4 = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: subnavFinal,
            nextArrow: <SubnavNextArrow className="adsol-subnav__next" />,
            prevArrow: <SubnavPrevArrow className="adsol-subnav__prev" />,
        };

        if (items.length >= 4) {
            // Use normal gallery settings
            carouselSettings = settings;
        } else if (items.length === 3) {
            carouselSettings = settings2;
            navClass = 'third';
        } else if (items.length === 2) {
            carouselSettings = settings3;
            navClass = 'half';
        } else {
            carouselSettings = settings4;
            navClass = 'single';
        }

        return (
            <div className={`adsol-subnav ${navClass}`} >
                <div className="container--wide">
                  <Slider {...carouselSettings} ref={slider => (this.slider = slider)}>
                        {subNav}
                  </Slider>
                </div>
            </div>
          );
    }
}

export default AdsSubNav;