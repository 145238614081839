import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';

class AdsNavItem extends Component {
    render() {
        let activeClass = '';

        const { title, 
                description, 
                iconActive, 
                iconInactive, 
                link } = this.props;

        const linkArr = this.props.link.split('/'),
              locationArr = window.location.pathname.split('/');

        if (linkArr[0] === locationArr[2]) {
            // Add active class to nav items when they are on sub pages
            activeClass = 'active';
        }

        return (
            <NavLink 
                className={`ads-nav__item ${activeClass}`}
                activeClassName='active'
                to={`/ad-solutions/${link}`}
                key={`/ad-solutions/${link}`}
                onClick={() => {ReactGA.event({ category: 'Ad Solutions', action: 'Click', label: 'Nav - ' + title})}}>
                <div className="ads-nav__item--icon">
                    <img className="inactive" src={iconInactive} alt={`Icon - ${title}`}/>
                    <img className="active" src={iconActive} alt={`Icon - ${title}`}/>
                </div>
                <h2>{title}</h2>
                {description && (
                    <p>{description}</p>
                )}
                <span>Details</span>
            </NavLink>     
          );
    }
}

export default AdsNavItem;





