import React, { Component } from 'react';

import SearchForm from './SearchForm';

class SearchModal extends Component {
  render() {
    const { searchModalState, focusSearchInput } = this.props;

    return (
      <div className={`search-modal ${searchModalState ? 'opened' : ''}`}>
        <SearchForm focusSearchInput={focusSearchInput}/>
        <span className="search-modal__close-btn centered" onClick={this.props.toggleSearchModal}><span></span></span>
      </div>
    );
  }
}

export default SearchModal;