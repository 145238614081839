import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import Slider from "react-slick";

import OpportunityCarouselItem from './OpportunityCarouselItem';
import EditorialCalendar from '../EditorialCalendar';

class OpportunityModules extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(label) {
        ReactGA.event({
          category: 'Opportunities',
          action: 'Click',
          label: label
        });
    }

    render() {
        let moduleArr = [],
            modCount = 0;
        const { modules, showSubheadline } = this.props;

        // Setting for 3 across carousel
        var settings = {
          dots: true,
          infinite: true,
          slidesToShow: 3,
          nextArrow: <div className="hot-op__nav--next"></div>,
          prevArrow: <div className="hot-op__nav--prev"></div>,
          responsive: [
            {
              breakpoint: 1100,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };

        // Carousel Settings if there are only two slides
        var settings2 = {
          dots: true,
          infinite: true,
          slidesToShow: 2,
          nextArrow: <div className="hot-op__nav--next"></div>,
          prevArrow: <div className="hot-op__nav--prev"></div>,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };

        if (modules.length >= 1) {
            console.log(modules);
        }

        modules.forEach(function(item) {
            if (item.sys.contentType.sys.id === 'calendar') {
                moduleArr.push(
                    <div>
                        <EditorialCalendar item={item}/>
                    </div>
                );
            } else if (item.sys.contentType.sys.id === 'carousel') {
                item.fields.slides && (
                    moduleArr.push(
                        <div className="hot-op__container">
                            <div className="hot-op__section">
                                {modCount === 0 && (
                                    showSubheadline && (
                                        <div>
                                            <h4 className="hot-op__subheadline"><span>More Hot Opportunities</span></h4>
                                        </div>
                                    )
                                )} 
                                <div className="hot-op__section--header">
                                    {item.fields.carouselDetailPageLink && (
                                        <div>
                                            <h2 className="cap">
                                                <Link to={`../hot-opportunities/${item.fields.carouselDetailPageLink.fields.slug}`} onClick={() => {ReactGA.event({ category: 'Opportunities', action: 'Click', label: 'See More - ' + item.fields.heading})}}>
                                                    {item.fields.heading}
                                                </Link>
                                            </h2>
                                                
                                            <Link to={`../hot-opportunities/${item.fields.carouselDetailPageLink.fields.slug}`} className="hot-op__link" onClick={() => {ReactGA.event({ category: 'Opportunities', action: 'Click', label: 'See More - ' + item.fields.heading})}}>See Details</Link>
                                        </div>
                                    )}
                                    {!item.fields.carouselDetailPageLink && (
                                        <h2 className="cap">{item.fields.heading}</h2>
                                    )}
                                </div>
                                
                                {item.fields.slides.length >= 3 && (
                                    <div className="hot-op__gallery">
                                     
                                        <Slider {...settings}>
                                            {item.fields.slides.map((subitem, index) => (
                                                <OpportunityCarouselItem
                                                    key={index}
                                                    url={subitem.fields.url}
                                                    image={subitem.fields.image.fields.file.url}
                                                    heading={subitem.fields.heading}
                                                    subheading={subitem.fields.subheading}
                                                    description={subitem.fields.excerpt}
                                                    materialLink={subitem.fields.materialLink}/>
                                            ))}
                                        </Slider>
                                    </div>
                                )}
                                {item.fields.slides.length === 2 && (
                                    <div className="hot-op__gallery--sm">   
                                        <Slider {...settings2}>
                                            {item.fields.slides.map((subitem, index) => (
                                                <OpportunityCarouselItem
                                                    key={index}
                                                    url={subitem.fields.url}
                                                    image={subitem.fields.image.fields.file.url}
                                                    heading={subitem.fields.heading}
                                                    subheading={subitem.fields.subheading}
                                                    description={subitem.fields.excerpt}
                                                    materialLink={subitem.fields.materialLink}/>
                                            ))}
                                        </Slider>
                                    </div>
                                )}
                                {item.fields.slides.length === 1 && (
                                    <div className="hot-op__gallery--flex"> 
                                        {item.fields.slides.map((subitem, index) => (
                                            <OpportunityCarouselItem
                                                key={index}
                                                url={subitem.fields.url}
                                                image={subitem.fields.image.fields.file.url}
                                                heading={subitem.fields.heading}
                                                subheading={subitem.fields.subheading}
                                                description={subitem.fields.excerpt}
                                                materialLink={subitem.fields.materialLink}/>
                                        ))}
                                    </div>
                                )}
                            </div>
                            
                        </div>
                    )
                )
            }

            modCount++;
        })



        return (
            <section className="hot-op">
                {moduleArr}
            </section>
        );
    }
}

export default OpportunityModules;
