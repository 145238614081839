import React, { Component } from 'react';

class Page404 extends Component {
	componentDidMount() {
		document.title = 'Error 404 | Warner Bros. Discovery';
	}

	render() {
		return (
		    <div className="section__fourofour">
		    	<div className="container">
		    		<h1>404: This page does not exist</h1>
		    	</div>
		    </div>
		  );
	}
}

export default Page404;
